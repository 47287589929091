import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import r from "styled-components";
import { useRef as $ } from "react";
import { r as m } from "../../polished.esm-wTGEn2h0.js";
import { I as f } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getRadii as g, getPaletteBorder as p, getPaletteBase as h, getPaletteColors as a, getPaletteBackground as y, getPaletteForeground as u, getSpacings as k } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import { sizeStyles as z, iconSizeStyles as j, inputSizeStyles as C } from "./styles.es.js";
import { SwitchControlLayout as S } from "../Switch/index.es.js";
import "../Icon/variants.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
import "../../index.module-6I51pdhn.js";
import "../Switch/styles.es.js";
import "../../useIsMounted-8GJRhVk0.js";
import "@floating-ui/react-dom";
import "../Typography/index.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../FieldControl/index.es.js";
const P = r.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  border-radius: ${g("xs")};
  border: 1px solid ${p("primary")};
  background-color: ${h("transparent")};
  
  ${(e) => z[e.$size]};

  &:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 4px ${(e) => m(a("gray.400")(e), 0.14)};
  }
  
  &[aria-checked="true"], &[data-indeterminate="true"] {
    background-color: ${(e) => a(e.$color)(e)[600]};
    border-color: ${(e) => a(e.$color)(e)[600]};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(e) => m(a(e.$color)(e)[500], 0.24)};
    }
  }
  
  &:disabled {
    background-color: ${y("disabled.subtle")};
    border-color: ${p("disabled.subtle")};
  }
`, w = r.div`
  display: inline-flex;
  
  color: ${u("white")};

  &[aria-disabled="true"] {
    color: ${u("disabled.subtle")};
  }
`, I = r(f)`
  ${(e) => j[e.$size]};
`, v = r.input`
  position: absolute;
  opacity: 0;
  
  ${(e) => C[e.$size]};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`, B = (e) => {
  const {
    size: t = "md",
    color: n = "brand",
    checked: i = !0,
    indeterminate: s,
    onFocus: d,
    ...l
  } = e, c = $(null), b = (x) => {
    c.current && c.current.focus(), d && d(x);
  };
  return /* @__PURE__ */ o.jsxs(
    P,
    {
      $size: t,
      $color: n,
      "aria-checked": i,
      "data-indeterminate": s,
      disabled: l.disabled,
      ref: c,
      children: [
        /* @__PURE__ */ o.jsx(
          v,
          {
            $size: t,
            type: "checkbox",
            checked: i,
            onFocus: b,
            ...l
          }
        ),
        (e.indeterminate || e.checked) && /* @__PURE__ */ o.jsx(
          w,
          {
            "aria-disabled": e.disabled,
            children: /* @__PURE__ */ o.jsx(
              I,
              {
                $size: t,
                variant: s ? "minus" : "check"
              }
            )
          }
        )
      ]
    }
  );
}, F = r.div`
  display: flex;
  width: 344px;

  gap: ${k("md")};
`, de = (e) => {
  const {
    label: t,
    helperMessage: n,
    errorMessage: i,
    ...s
  } = e;
  return /* @__PURE__ */ o.jsxs(F, { children: [
    /* @__PURE__ */ o.jsx(B, { ...s }),
    /* @__PURE__ */ o.jsx(
      S,
      {
        label: t,
        helperMessage: n,
        errorMessage: i
      }
    )
  ] });
};
export {
  B as BaseCheckbox,
  de as default
};
