import { j as t } from "../../jsx-runtime-NmMXPjeo.js";
import i from "styled-components";
import { BaseTextField as a } from "../../components/TextField/index.es.js";
import { I as m } from "../../Icon-qMvz1KAz.js";
import "react";
import "../../components/Icon/countryVariants.es.js";
import "../../components/Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getBreakpointMediaQuery as n, getPaletteForeground as o } from "../../components/Theme/utils.es.js";
import "../../components/CssBaseline/index.es.js";
import "../../components/FieldControl/index.es.js";
import "../../styles-YtnfNTLW.js";
import "../../components/Typography/index.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../../components/Icon/variants.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../../components/Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../../components/Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../../components/CssBaseline/normalize.es.js";
const l = i(a)`
  
  ${n("max", "lg")} {
    width: 100%;
  }
`, d = i(m)`
  color: ${o("quarterary.default")}
`, s = i(m)`
  color: ${o("quinary.default")};
  cursor: pointer;
  
  visibility: ${(r) => r.$isHidden ? "hidden" : "visible"};
  
  &:hover {
    color: ${o("quinary.hover")};
  }
`, R = (r) => {
  const {
    onClearIconClick: p,
    ...e
  } = r;
  return /* @__PURE__ */ t.jsx(
    l,
    {
      startAdornment: /* @__PURE__ */ t.jsx(
        d,
        {
          variant: "search-lg",
          width: 20,
          height: 20
        }
      ),
      endAdornment: /* @__PURE__ */ t.jsx(
        s,
        {
          variant: "x-circle",
          color: "quinary",
          width: 20,
          height: 20,
          onClick: p,
          $isHidden: !e.value
        }
      ),
      ...e
    }
  );
};
export {
  R as default
};
