import { TenantContractsTable } from 'src/features/tenant-contracts';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from '../Tenant';

const TenantContractsPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantContractsTable tenantID={params.tenantID}/>
  );
};

export default TenantContractsPage;
