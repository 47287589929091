import { RouteObject } from 'react-router-dom';

import TenantsPage from './TenantsPage';
import tenantsLink from './link';

const tenantsRoute: RouteObject = {
  path: tenantsLink(),
  element: <TenantsPage/>,
};

export default tenantsRoute;
