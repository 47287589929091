import { UserDetails } from 'src/features/user-details';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { UserNavigationContext } from '../User';

const UserDetailsPage = () => {
  const { params } = useNavigationContext<UserNavigationContext>();

  return (
    <UserDetails userID={params.userID}/>
  );
};

export default UserDetailsPage;
