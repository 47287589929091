import { css as t } from "styled-components";
const s = {
  sm: 16,
  md: 20
}, m = 13, d = {
  sm: t`
    width: ${s.sm}px;
    height: ${s.sm}px;
    padding: 2px;
  `,
  md: t`
    width: ${s.md}px;
    height: ${s.md}px;
    padding: 3px;
  `
}, p = {
  sm: t`
    width: 12px;
    height: 12px;
  `,
  md: t`
    width: 14px;
    height: 14px;
  `
}, e = {
  sm: t`
    transform: scale(${s.sm / m});
  `,
  md: t`
    transform: scale(${s.md / m});
  `
};
export {
  p as iconSizeStyles,
  e as inputSizeStyles,
  d as sizeStyles
};
