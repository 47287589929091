import { createColumnHelper } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import styled from 'styled-components';

import { FetchTenantUsersParams, TenantUserWithGroups } from './services';
import { EmptyTable, TableCell, TableHeaderCell } from '@oncore/ui/Table';
import OncoreTable from '@oncore/ui/shared/OncoreTable';
import UserTableCell from 'src/features/users/UserTableCell';
import { getTenantUsersQuery } from './queries';
import Typography from '@oncore/ui/Typography';
import Badge from '@oncore/ui/Badge';
import { getSpacings } from '@oncore/ui/Theme';
import { formatPhoneNumber } from '@oncore/ui/utils';

export type TenantUsersTableProps = {
  tenantID: string;
};

const columnHelper = createColumnHelper<TenantUserWithGroups>();

const GroupTableCell = styled(TableCell)`
  flex-wrap: wrap;
  
  gap: ${getSpacings('xs')};
`;

const columns = [
  columnHelper.accessor('user.email', {
    header: function UserHeaderCell() {

      return (
        <TableHeaderCell
          span="320px">
          User
        </TableHeaderCell>
      );
    },
    cell: function UserCell(info) {
      const {
        firstName,
        lastName
      } = info.row.original.user;

      const displayName = `${firstName} ${lastName}`;

      return (
        <UserTableCell
          span="320px"
          displayName={displayName}
          emailAddress={info.getValue()}/>
      );
    },
  }),
  columnHelper.accessor('user.mobile', {
    id: 'userMobile',
    header: () => (
      <TableHeaderCell
        span="180px">
        Mobile
      </TableHeaderCell>
    ),
    cell: (info) => {

      return (
        <TableCell
          span="180px">
          <Typography size="sm">
            {formatPhoneNumber(info.getValue() || '')}
          </Typography>
        </TableCell>
      );
    },
  }),
  columnHelper.accessor('groups', {
    id: 'userGroups',
    header: () => (
      <TableHeaderCell size="300px">
        Groups
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        items,
        totalCount,
      } = info.getValue();

      const remainingItemsCount = totalCount - items.length;

      return (
        <GroupTableCell size="300px">
          {
            items.map((group, index) => (
              <Badge
                key={index}
                color="gray"
                text={group.displayName}/>
            ))
          }
          {
            remainingItemsCount > 0 && (
              <Badge
                color="gray"
                text={`+${remainingItemsCount}`}/>
            )
          }
        </GroupTableCell>
      );
    },
  }),
];

const TenantUsersTable = (props: TenantUsersTableProps) => {
  const { tenantID } = props;

  const tenantUsersQuery = getTenantUsersQuery(tenantID);

  const initialPageParam: Omit<FetchTenantUsersParams, 'tenantID'> = {
    continuationToken: '',
  };

  const infiniteQuery = useInfiniteQuery({
    ...tenantUsersQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await tenantUsersQuery.queryFn({
        ...data.pageParam,
        tenantID,
      });
    },
    getNextPageParam: (lastPage) => {
      const { continuationToken } = lastPage;

      return continuationToken ? {
        continuationToken,
      } : undefined;
    },
  });

  const queryData = infiniteQuery.data;

  const tableData = useMemo(() => {

    if (queryData) {
      return queryData.pages.flatMap((page) => page.items);
    }

    return [];
  }, [queryData?.pages]);

  if (infiniteQuery.isFetched && tableData.length === 0) {
    return (
      <EmptyTable/>
    );
  }

  return (
    <OncoreTable
      columns={columns}
      data={tableData}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 40,
      }}/>
  );
};

export default TenantUsersTable;
