import styled from 'styled-components';
import { useCallback } from 'react';

import {
  getSpacings,
} from '@oncore/ui/Theme';
import Switch from '@oncore/ui/Switch';

export type FeatureDetails<I = string> = {
  id: I;
  name: string;
  description: string;
  disabled?: boolean;
};

export type FeaturesFormProps<I = string> = {
  featureDetailsList: FeatureDetails<I>[];
  enabledFeatures: I[];
  onChange?: (features: I[]) => void;
  className?: string;
};

const Container = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${getSpacings('2xl')} 20px;
  align-self: stretch;
`;

const FeaturesForm = <I extends string = string>(props: FeaturesFormProps<I>) => {
  const {
    featureDetailsList,
    enabledFeatures,
    onChange,
    className,
  } = props;

  const handleFeatureToggle = (feature: I, isEnabled: boolean) => {
    let newEnabledFeatures = [
      ...enabledFeatures,
    ];

    if (isEnabled) {
      newEnabledFeatures.push(feature);
    } else {
      newEnabledFeatures = newEnabledFeatures.filter((item) => item !== feature);
    }

    onChange && onChange(newEnabledFeatures);
  };

  const renderSwitch = useCallback((details: FeatureDetails<I>) => {
    const {
      id,
      name,
      description,
      disabled,
    } = details;

    const isChecked = Boolean(enabledFeatures.find((feature) => feature === id));

    return (
      <Switch
        size="sm"
        key={id}
        label={name}
        helperMessage={description}
        checked={isChecked}
        disabled={disabled}
        onChange={(_event, newValue) => handleFeatureToggle(id, newValue)}/>
    );
  }, [
    featureDetailsList,
    enabledFeatures,
  ]);

  return (
    <Container className={className}>
      {
        featureDetailsList.map(renderSwitch)
      }
    </Container>
  );
};

export default FeaturesForm;
