import styled from 'styled-components';
import { FieldPath, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getBreakpointMediaQuery,
  getSpacings,
  getWidths,
} from '@oncore/ui/Theme';
import Typography from '@oncore/ui/Typography';
import Divider from '@oncore/ui/Divider';
import { getUpdateUserMutation } from './mutations';
import { updateUserDetailsSchema } from './schemas';
import UserDetailsPageFooter from './UserDetailsPageFooter';
import { BaseTextField } from '@oncore/ui/TextField';
import { UpdateUserDisplayNameParams } from './services';

export type UserDetailsUpdatePageProps = {
  userID: string;
  displayName: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0 ${getSpacings('xl')} 0 ${getSpacings('xl')};
  }
`;

const StyledBaseTextField = styled(BaseTextField)`
  width: 512px;
  
  ${getBreakpointMediaQuery('max', 'lg')} {
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${getSpacings('xl')} ${getSpacings('4xl')};
  align-self: stretch;
  flex-wrap: wrap;
`;

const SectionLabel = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 280px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const SectionInputs = styled.div`
  display: flex;
  min-width: ${getWidths('xxs')};
  max-width: 512px;

  ${getBreakpointMediaQuery('max', 'lg')} {
    max-width: 100%;
  }
    
  flex-direction: column;
  align-items: flex-start;
  gap: ${getSpacings('xl')};
  flex: 1 0 0;
`;

const UserDetailsUpdatePage = (props: UserDetailsUpdatePageProps) => {
  const {
    userID,
    displayName,
  } = props;

  const queryClient = useQueryClient();

  const userMutation = useMutation(getUpdateUserMutation(queryClient));

  const defaultUser = {
    displayName: displayName
  };

  const {
    getValues, 
    formState: { isDirty }, 
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(updateUserDetailsSchema),
    defaultValues: {
      userID: userID,
      displayName: defaultUser.displayName,
    }
  });

  const handleSubmit = () => {
    if (isDirty) {
      userMutation.mutate(getValues());
    }
  };

  const handleDisplayNameChange = (name: FieldPath<UpdateUserDisplayNameParams>, value: string) => {
    setValue(name, value, {
      shouldDirty: true
    });
  };

  return (
    <Container>
      <Typography size="lg" weight="semibold">
        User details
      </Typography>
      <Divider spacing="2xl"/>
      <SectionContainer>
        <SectionLabel>
          <Typography size="sm" weight="semibold" color="secondary">
            Display name
          </Typography>
        </SectionLabel>
        <SectionInputs>
          <StyledBaseTextField
            value={watch('displayName')}
            type="text"
            onChange={(event) =>
              handleDisplayNameChange('displayName', event.target.value)}/>
        </SectionInputs>
      </SectionContainer>
      <Divider spacing="2xl"/>
      <UserDetailsPageFooter
        onClick={handleSubmit}
        disabled={userMutation.isPending}
      />
    </Container>
  );
};

export default UserDetailsUpdatePage;
