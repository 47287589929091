import { g as i } from "../getInitials-mV6BUugk.js";
import { i as c } from "../isExhaustive-ua1FyxmG.js";
import { l as b } from "../lookUpByPath-gjQB8xFz.js";
import { isValidPhoneNumber as o, formatPhoneNumberIntl as n } from "react-phone-number-input";
const m = (r) => {
  const t = /^(\d{4})(\d{3})(\d{3})$/, e = r.replace(/\D/g, "").match(t);
  return e ? `${e[1]} ${e[2]} ${e[3]}` : "";
}, s = (r) => {
  try {
    if (!o(r))
      throw new Error("Invalid phone number");
    return n(r);
  } catch {
    const t = m(r);
    return t || r;
  }
};
export {
  s as formatPhoneNumber,
  i as getInitials,
  c as isExhaustive,
  b as lookUpByPath
};
