import { j as e } from "../../jsx-runtime-NmMXPjeo.js";
import a from "styled-components";
import "../../styles-YtnfNTLW.js";
import d from "../Typography/index.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteColors as r, getRadii as c, getSpacings as g } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import { badgeSizeStyles as x } from "./styles.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
const $ = a.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  
  background: ${(o) => r(o.$color)(o)[50]};
  color: ${(o) => r(o.$color)(o)[700]};
  
  border-radius: ${c("full")};
  border: 1px solid ${(o) => r(o.$color)(o)[200]};
  gap: ${g("xs")};
  
  ${(o) => x[o.$size]};
`, G = (o) => {
  const {
    text: t,
    color: m = "brand",
    size: i = "sm",
    className: s,
    startAdornment: n,
    endAdornment: p
  } = o, l = i === "sm" ? "xs" : "sm";
  return /* @__PURE__ */ e.jsxs(
    $,
    {
      className: s,
      $color: m,
      $size: i,
      children: [
        n,
        !!t && /* @__PURE__ */ e.jsx(
          d,
          {
            size: l,
            weight: "medium",
            color: "none",
            children: t
          }
        ),
        p
      ]
    }
  );
};
export {
  G as default
};
