import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

const versionQueryDocument = graphql(`
  query VersionQuery {
    version {
      major
      minor
      build
      revision
      formatted
    }
  }
`);

export const fetchAppVersion = async () => {
  const result = await graphQLClient.request(versionQueryDocument);
  
  return result.version;
};
