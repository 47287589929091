import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

type GenericTenant = {
  id: string;
  displayName: string;
  region: {
    displayName: string;
  };
  users: {
    totalCount: number;
  };
};

type AgencyTenant = GenericTenant & {
  type: 'Agency';
};

type EnterpriseTenant = GenericTenant & {
  type: 'Enterprise';
};

export type Tenant = AgencyTenant | EnterpriseTenant;

export type FetchTenantsQueryData = {
  items: Tenant[];
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  continuationToken: string;
}

export type TenantsFilter = {
  query: string;
};

export type FetchTenantsParams = {
  filter: TenantsFilter
} | {
  continuationToken: string;
}

const fetchTenantsQueryDocument = graphql(`
  query Tenants($filter: TenantsFilter, $continuationToken: String) {
    tenants(filter: $filter, continuationToken: $continuationToken) {
      continuationToken
      totalCount
      pageSize
      pageNumber
      items {
        ... on Enterprise {
          __typename
          id
          displayName
          region {
            displayName
          }
          users {
            totalCount
          }
        }
        ... on Agency {
          __typename
          id
          displayName
          region {
            displayName
          }
          users {
            totalCount
          }
        }
      }
    }
  }
`);

export const fetchTenants = async (params?: FetchTenantsParams): Promise<FetchTenantsQueryData> => {
  const variables = params || {};
  const { tenants } = await graphQLClient.request(fetchTenantsQueryDocument, variables);
  const items = tenants.items.map((item) => ({
    ...item,
    type: item.__typename,
  }));

  return {
    ...tenants,
    continuationToken: tenants.continuationToken ?? '',
    items,
  };
};
