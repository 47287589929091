import {
  fetchTenantTimesheets,
  TimesheetsFilter
} from './services';
import { tenantKeys } from '../tenant-details';

export const tenantTimesheetKeys = {
  timesheets: (tenantID: string, filter?: TimesheetsFilter) => [
    ...tenantKeys.tenant(tenantID),
    'timesheets',
    filter,
  ] as const,
};

export const getTenantTimesheetsQuery = (tenantID: string, filter?: TimesheetsFilter) => ({
  queryKey: tenantTimesheetKeys.timesheets(tenantID, filter),
  queryFn: fetchTenantTimesheets,
});
