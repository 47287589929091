import { css as t } from "styled-components";
import { r as i } from "../../polished.esm-wTGEn2h0.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteColors as e, getPaletteBackground as c, getRadii as l, getPaletteBase as a, getSpacings as n, getPaletteBorder as d } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const L = {
  sm: t`
    height: 36px;
  `,
  md: t`
    height: 44px;
  `
}, r = {
  ghost: t`
    color: ${(o) => e(o.$color)(o)[700]};
    background-color: ${(o) => e(o.$color)(o)[50]};
  `,
  shadow: t`
    color: ${(o) => e(o.$color)(o)[700]};
    background-color: ${c("primary.default")};

    &:not(:focus) {
      box-shadow:
        0px 1px 3px 0px ${(o) => i(e(o.$color)(o)[900], 0.1)},
        0px 1px 2px 0px ${(o) => i(e(o.$color)(o)[900], 0.06)};
    }
  `,
  lined: t`
    color: ${(o) => e(o.$color)(o)[700]};
    border-color: ${(o) => e(o.$color)(o)[600]};
  `,
  "lined-ghost": t`
    color: ${(o) => e(o.$color)(o)[700]};
    border-color: ${(o) => e(o.$color)(o)[600]};
    background-color: ${(o) => e(o.$color)(o)[50]};
  `
}, O = {
  ghost: t`
    border: none;
    
    border-radius: ${l("sm")};
    
    ${(o) => o.$isSelected ? r.ghost : void 0}

    &:hover:enabled, &:active:enabled {
      ${r.ghost}
    }
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => i(e(o.$color)(o)[500], 0.24)};
    }
    
  `,
  shadow: t`
    border: none;

    border-radius: ${l("sm")};

    ${(o) => o.$isSelected ? r.shadow : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r.shadow};
    }
    
    &:focus {
      box-shadow:
        0px 1px 3px 0px ${(o) => i(e(o.$color)(o)[900], 0.1)},
        0px 1px 2px 0px ${(o) => i(e(o.$color)(o)[900], 0.06)},
        0px 0px 0px 4px ${(o) => i(e(o.$color)(o)[400], 0.14)};
    }
  `,
  lined: t`
    border: none;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}: 
      2px solid ${a("transparent")};

    ${(o) => o.$isSelected ? r.lined : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r.lined}
    }
  `,
  "lined-ghost": t`
    border: none;

    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      2px solid ${a("transparent")};

    ${(o) => o.$isSelected ? r["lined-ghost"] : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r["lined-ghost"]}
    }
  `
}, j = {
  horizontal: t``,
  vertical: t`
    width: 100%;
    justify-content: flex-start;
  `
}, A = {
  ghost: t`
    padding: ${n("xs")};
  `,
  shadow: t`
    padding: ${n("xs")};
    border-radius: ${l("lg")};
    border: 1px solid ${d("secondary")};
    background-color: ${c("secondary.alt")};
  `,
  lined: t`
    gap: 0px;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      1px solid ${d("secondary")};
  `,
  "lined-ghost": t`
    gap: 0px;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      1px solid ${d("secondary")};
  `
}, C = {
  horizontal: t`
    width: 100%;
    flex-direction: row;
  `,
  vertical: t`
    flex-direction: column;
  `
};
export {
  C as tabListOrientationStyles,
  A as tabListVariantStyles,
  j as tabOrientationStyles,
  L as tabSizeStyles,
  O as tabVariantStyles
};
