import { all, spawn, call } from 'typed-redux-saga';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import userSagas from './user/sagas';
import environmentSagas from './environment/sagas';

export default function* sagas() {
  const sagaList = [
    routinePromiseWatcherSaga,
    userSagas,
    environmentSagas
  ];

  yield* all(
    sagaList.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield* call(saga);
            break;
          } catch (error) {
            console.error(error);
          }
        }
      })
    )
  );
}
