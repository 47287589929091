import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebouncedCallback } from 'use-debounce';

import { getUpdateEnterpriseFeaturesMutation } from './mutations';
import FeaturesForm from 'src/components/FeaturesForm';
import { TENANT_ENTERPRISE_FEATURES } from './constants';
import { updateEnterpriseFeaturesSchema } from './schemas';
import { EnterpriseFeature } from 'src/types/tenants';

export type EnterpriseFeaturesFormProps = {
  tenantID: string;
  features: EnterpriseFeature[];
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledFeaturesForm = styled(FeaturesForm)`
  max-width: 800px;
`;

const EnterpriseFeaturesForm = (props: EnterpriseFeaturesFormProps) => {
  const {
    tenantID,
    features,
  } = props;

  const queryClient = useQueryClient();

  const enterpriseMutation = useMutation(getUpdateEnterpriseFeaturesMutation(queryClient));

  const {
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(updateEnterpriseFeaturesSchema),
    defaultValues: {
      tenantID,
      features,
    },
  });

  const handleChange = (features: EnterpriseFeature[]) => {
    setValue('features', features);
    handleDebouncedSubmit();
  };

  const handleSubmit = () => {
    enterpriseMutation.mutate(getValues());
  };

  const handleDebouncedSubmit = useDebouncedCallback(handleSubmit, 500);

  return (
    <Container>
      <StyledFeaturesForm
        featureDetailsList={TENANT_ENTERPRISE_FEATURES}
        enabledFeatures={watch('features')}
        onChange={handleChange}/>
    </Container>
  );
};

export default EnterpriseFeaturesForm;
