import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import m from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as i, getBreakpointMediaQuery as a } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import "../../styles-YtnfNTLW.js";
import d from "../Typography/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../clsx.m-7d8NmkQq.js";
const p = m.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  padding: ${i("3xl")};
  padding-bottom: ${i("4xl")};
  gap: ${i("2xl")};
  
  ${a("max", "lg")} {
    padding-left: 0;
    padding-right: 0;
    
    padding-bottom: ${(t) => t.$removeBottomPaddingInMobile ? 0 : i("4xl")(t)};
  }
`, E = (t) => {
  const {
    children: r,
    className: e,
    removeBottomPaddingInMobile: n = !1
  } = t;
  return /* @__PURE__ */ o.jsx(
    p,
    {
      className: e,
      $removeBottomPaddingInMobile: n,
      children: r
    }
  );
}, l = (t) => /* @__PURE__ */ o.jsx(
  d,
  {
    variant: "display",
    size: "xs",
    weight: "semibold",
    ...t
  }
), s = m.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  
  gap: ${i("md")};

  ${a("max", "lg")} {
    padding-left: ${i("2xl")};
    padding-right: ${i("2xl")};
  }
`, H = (t) => {
  const {
    title: r,
    children: e,
    className: n
  } = t;
  return /* @__PURE__ */ o.jsxs(s, { className: n, children: [
    /* @__PURE__ */ o.jsx(l, { children: r }),
    e
  ] });
};
export {
  H as PageContentHeader,
  l as PageContentTitle,
  E as default
};
