import { j as m } from "../../jsx-runtime-NmMXPjeo.js";
import p from "styled-components";
import { getDividerStyles as n } from "./styles.es.js";
import "react";
import "../Theme/themes.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../palettes-foWluF5S.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../Theme/utils.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/index.es.js";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const e = p.div`
  ${(t) => n(t.$orientation, t.$spacing)}
`, S = (t) => {
  const {
    className: i,
    spacing: o = "none",
    orientation: r = "horizontal"
  } = t;
  return /* @__PURE__ */ m.jsx(
    e,
    {
      $spacing: o,
      className: i,
      $orientation: r
    }
  );
};
export {
  S as default
};
