import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantContractsNavigationContext = TenantNavigationContext;

const tenantContractsLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/contracts` as const;
};

export const tenantContractsPath = tenantContractsLink({
  tenantID: ':tenantID',
});

export default tenantContractsLink;
