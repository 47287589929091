import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

export type UpdateEnterpriseSettingsResult = {
  id: string;
}

export type Settings = {
  isCostCenterFieldRequired: boolean;
  isLineManagerRequired: boolean;
  isProjectFieldRequired: boolean;
  isTimesheetApproversRequired: boolean;
  workCoverIndustryClassificationCodeID: string;
  workCoverIndustryClassificationCode: string;
};

export type InitialSettings = {
  isCostCenterFieldRequired: boolean | null | undefined;
  isLineManagerRequired: boolean | null | undefined;
  isProjectFieldRequired: boolean | null | undefined;
  isTimesheetApproversRequired: boolean | null | undefined;
  workCoverIndustryClassificationCodeID: string | null | undefined;
};

export type WICCode = {
  id: string;
  code: string;
  displayName: string;
};

export type UpdateEnterpriseSettingsParams = {
  tenantID: string;
  settings: Settings;
}

const fetchTenantSettingsQueryDocument = graphql(`
  query TenantSettings($tenantID: ID!) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        __typename
        id
        displayName
        isCostCenterFieldRequired
        isLineManagerRequired
        isProjectFieldRequired
        isTimesheetApproversRequired
        features
        wicCodeID
      }
    ... on Agency {
        __typename
        id
      }
    }
    reference {
      workCoverIndustryCodes {
        items {
          id
          displayName
          code
        }
      }
    }
  }
`);

const updateEnterpriseSettingsMutationDocument = graphql(`
  mutation UpdateEnterpriseSettings($tenantID: ID!, $settings: EnterpriseSettings!) {
    enterprise: updateEnterpriseSettings(tenantID: $tenantID, settings: $settings) {
      id
    }
  }
`);

export const updateEnterpriseSettingsMutation = async (params: UpdateEnterpriseSettingsParams):
  Promise<UpdateEnterpriseSettingsResult> => {
  const { enterprise } = await graphQLClient.request(updateEnterpriseSettingsMutationDocument, params);

  return enterprise;
};

export const fetchTenantSettings = async (tenantID: string) => {
  const { tenant, reference } = await graphQLClient.request(fetchTenantSettingsQueryDocument, {
    tenantID
  });

  return {
    tenant,
    reference
  };
};
