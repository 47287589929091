import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import s from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBackground as a, getSpacings as n, getPaletteBorder as l } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import { S as c, u as d } from "../../hooks-lbuCOKOu.js";
import { I as x } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import f from "../IconButton/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
import "../Icon/variants.es.js";
import "../IconButton/styles.es.js";
import "../Button/styles.es.js";
const g = s.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background: ${a("primary.default")};
`, M = (t) => {
  const {
    isOpen: r,
    onClose: e,
    ...i
  } = t;
  return /* @__PURE__ */ o.jsx(
    c,
    {
      isOpen: r,
      onClose: e,
      children: r && /* @__PURE__ */ o.jsx(g, { ...i })
    }
  );
}, u = s.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 64px;
  
  padding: ${n("lg")} ${n("xl")};
  gap: ${n("lg")};
  
  border-bottom: 1px solid ${l("secondary")};
`, Q = (t) => {
  const {
    children: r,
    className: e,
    onBack: i
  } = t, { onClose: m } = d(), p = () => {
    i ? i() : m && m();
  };
  return /* @__PURE__ */ o.jsxs(u, { className: e, children: [
    /* @__PURE__ */ o.jsx(
      f,
      {
        variant: "outlined",
        color: "gray",
        onClick: p,
        children: /* @__PURE__ */ o.jsx(
          x,
          {
            variant: "arrow-left"
          }
        )
      }
    ),
    r
  ] });
}, h = s.div`
  display: flex;
  flex: 1;
  overflow: auto;
`, T = (t) => /* @__PURE__ */ o.jsx(h, { ...t });
export {
  T as ScreenBody,
  Q as ScreenHeader,
  M as default
};
