import { RouteObject } from 'react-router-dom';

import TenantDetailsPage from './TenantDetailsPage';

import { tenantDetailsPath } from './link';

const tenantDetailsRoute: RouteObject = {
  path: tenantDetailsPath,
  element: <TenantDetailsPage/>,
};

export default tenantDetailsRoute;
