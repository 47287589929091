import styled from 'styled-components';

import {
  getSpacings,
  getBreakpointMediaQuery,
  useMediaQuery,
} from '@oncore/ui/Theme';
import UsersTable, { UsersTableProps } from './UsersTable';
import UsersFilter from './UsersFilter';
import { useUsersList } from './hooks';

export type UsersListProps = Pick<UsersTableProps, 'onUserClick'>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: ${getSpacings('2xl')};
`;

const StyledUsersFilter = styled(UsersFilter)`
  
  ${getBreakpointMediaQuery('max', 'lg')} {
    padding-left: ${getSpacings('2xl')};
    padding-right: ${getSpacings('2xl')};
  }
`;

const UsersList = (props: UsersListProps) => {
  const { onUserClick } = props;

  const isDesktopView = useMediaQuery('desktop');

  const usersListContext = useUsersList();

  const {
    values,
    queryValues,
    setQueryValues,
    resetFilter,
  } = usersListContext.queryParams;

  return (
    <Container>
      {
        isDesktopView && (
          <StyledUsersFilter
            filter={values}
            onFilterChange={setQueryValues}
            onClearIconClick={resetFilter}/>
        )
      }
      <UsersTable
        filter={queryValues}
        onClearFilterClick={resetFilter}
        onUserClick={onUserClick}/>
    </Container>
  );
};

export default UsersList;
