import { fetchAppVersion } from './services';

export const keys = {
  version: ['version'] as const,
};

export const appVersionQuery = () => ({
  queryKey: keys.version,
  queryFn: fetchAppVersion,
});
