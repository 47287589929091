import { QueryClient } from '@tanstack/react-query';
import { tenantKeys } from '../tenant-details';

import {
  updateAgencyFeatures,
  updateEnterpriseFeatures,
  UpdateTenantFeaturesResult,
} from './services';

export const getUpdateEnterpriseFeaturesMutation = (queryClient: QueryClient) => ({
  mutationFn: updateEnterpriseFeatures,
  onSuccess: (data: UpdateTenantFeaturesResult) => {
    queryClient.invalidateQueries({
      queryKey: tenantKeys.tenantFeatures(data.id)
    });
  },
});

export const getUpdateAgencyFeaturesMutation = (queryClient: QueryClient) => ({
  mutationFn: updateAgencyFeatures,
  onSuccess: (data: UpdateTenantFeaturesResult) => {
    queryClient.invalidateQueries({
      queryKey: tenantKeys.tenantFeatures(data.id)
    });
  },
});
