import { TenantFeatures } from 'src/features/tenant-features';
import { useNavigationContext } from '../../components/AppRouter/hooks.ts';
import { TenantNavigationContext } from '../Tenant';

const TenantFeaturesPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantFeatures tenantID={params.tenantID} />
  );
};

export default TenantFeaturesPage;
