import { RouteObject } from 'react-router-dom';

import UserPage from './UserPage';
import { userPath } from './link';
import UserIndexPage from './UserIndexPage';
import userDetailsRoute from '../UserDetails/route';

export type UserNavigationContext = {
  params: {
    userID: string;
  };
};

const userRoute: RouteObject = {
  path: userPath,
  element: <UserPage/>,
  children: [
    {
      path: '',
      element: <UserIndexPage/>
    },
    userDetailsRoute,
  ],
};

export default userRoute;
