import { j as r } from "../../jsx-runtime-NmMXPjeo.js";
import s, { css as I } from "styled-components";
import { forwardRef as j, useRef as P } from "react";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBorder as x, getSpacings as f, getRadii as v, getPaletteBackground as y, getEffects as B, getPaletteText as a, getPaletteForeground as d } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import { I as w } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import F from "../FieldControl/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
import "../Icon/variants.es.js";
import "../Typography/index.es.js";
import "../../clsx.m-7d8NmkQq.js";
const S = I`
  border-color: ${x("error.default")};
`, T = s.div`
  display: inline-flex;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  
  gap: ${f("md")};
  padding: ${f("md")} ${f("lg")};
  
  border-radius: ${v("md")};
  border: 1px solid ${x("primary")};
  
  background-color: ${y("primary.default")};
  
  &:focus-within, &:focus {
    box-shadow: ${B("focusRings.brand.xs")};
    border-color: ${x("brand.default")};
  }
  
  &[aria-disabled="true"] {
    background-color: ${y("disabled.default")};
  }
  
  ${(c) => c.$isInvalid ? S : ""};
`, R = s.input`
  width: 100%;
  border: none;
  background-color: transparent;
  
  color: ${a("primary.default")};
  caret-color: ${d("primary.default")};
  
  &:focus {
    outline: none;
  }
  
  &:disabled {
    color: ${a("disabled")};
  }

  &::-webkit-input-placeholder {
    color: ${a("placeholder")};
  }

  &::-moz-placeholder {
    color: ${a("placeholder")};
  }

  &::placeholder {
    color: ${a("placeholder")};
  }
`, b = s.div`
  display: inline-flex;
  
  color: ${d("quarterary.default")};

  &[aria-disabled="true"] {
    color: ${d("quinary.default")};
  }
`, A = s(w)`
  width: 16px;
  height: 16px;
  
  color: ${d("error.secondary")};
`, q = j(function(p, o) {
  const {
    isInvalid: l,
    className: m,
    startAdornment: t,
    endAdornment: n,
    containerProps: u,
    ...e
  } = p, {
    onClick: g,
    ...C
  } = u || {}, $ = P(null), k = (i) => {
    var h;
    g && g(i), (h = $.current) == null || h.focus();
  };
  return /* @__PURE__ */ r.jsxs(
    T,
    {
      className: m,
      $isInvalid: l,
      "aria-disabled": e.disabled,
      onClick: k,
      ...C,
      children: [
        !!t && /* @__PURE__ */ r.jsx(b, { "aria-disabled": e.disabled, children: t }),
        /* @__PURE__ */ r.jsx(
          R,
          {
            ref: (i) => {
              typeof o == "function" ? o(i) : o && (o.current = i), $.current = i;
            },
            ...e
          }
        ),
        l && /* @__PURE__ */ r.jsx(b, { children: /* @__PURE__ */ r.jsx(A, { variant: "alert-circle" }) }),
        !!n && /* @__PURE__ */ r.jsx(b, { "aria-disabled": e.disabled, children: n })
      ]
    }
  );
}), lr = j(function(p, o) {
  const {
    label: l,
    helperMessage: m,
    errorMessage: t,
    className: n,
    ...u
  } = p, e = !!t;
  return /* @__PURE__ */ r.jsx(
    F,
    {
      label: l,
      helperMessage: m,
      errorMessage: t,
      className: n,
      children: /* @__PURE__ */ r.jsx(
        q,
        {
          ref: o,
          isInvalid: e,
          ...u
        }
      )
    }
  );
});
export {
  q as BaseTextField,
  lr as default
};
