import { redirect, RouteObject } from 'react-router-dom';

import ErrorPage from 'src/components/page-templates/ErrorPage.tsx';
import Chrome from 'src/components/Chrome';
import logoutRoute from './Logout/route.tsx';
import tenantsRoute from './Tenants/route.tsx';
import usersRoute from './Users/route.tsx';
import userProfileRoute from './UserProfile/route.tsx';
import userRoute, { UserNavigationContext } from './User/route.tsx';
import {
  tenantRoute,
  TenantNavigationContext,
} from './Tenant/';
import tenantsLink from './Tenants/link';

export type RootNavigationContext = {
  tenant: TenantNavigationContext;
  user: UserNavigationContext;
};

const routes: RouteObject[] = [
  logoutRoute, {
    element: <Chrome />,
    errorElement: <ErrorPage/>,
    children: [
      tenantRoute,
      tenantsRoute,
      userProfileRoute,
      usersRoute,
      userRoute,
    ]
  }, {
    path: '/',
    loader: () => redirect(tenantsLink())
  }
];

export default routes;
