import { useState as s, useEffect as u } from "react";
const n = () => {
  const [
    e,
    t
  ] = s(!1);
  return u(() => {
    t(!0);
  }, []), e;
};
export {
  n as u
};
