import { RouteObject } from 'react-router-dom';

import TenantFeaturesPage from './TenantFeaturesPage';

import { tenantFeaturesPath } from './link.ts';

const tenantFeaturesRoute: RouteObject = {
  path: tenantFeaturesPath,
  element: <TenantFeaturesPage/>,
};

export default tenantFeaturesRoute;
