import { RouteObject } from 'react-router-dom';

import UsersPage from './UsersPage.tsx';
import usersLink from './link';

const usersRoute: RouteObject = {
  path: usersLink(),
  element: <UsersPage/>,
};

export default usersRoute;
