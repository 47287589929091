import { createColumnHelper } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import styled from 'styled-components';

import { EmptyTable, TableCell, TableHeaderCell } from '@oncore/ui/Table';
import Typography from '@oncore/ui/Typography';
import OncoreTable from '@oncore/ui/shared/OncoreTable';
import { getSpacings } from '@oncore/ui/Theme';
import { getTenantGroupsQuery } from './queries';
import BadgeComponent from '@oncore/ui/Badge';
import Avatar from '@oncore/ui/Avatar';
import {
  FetchTenantGroupsParams,
  Group
} from './services';
import { TENANT_PERMISSION_LABEL_MAPPER } from './constants';

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: ${getSpacings('xs')};
`;

const UserBadge = styled(BadgeComponent)`
  margin-right: ${getSpacings('xs')};
  padding-left: 3px;
`;

const ComponentBadge = styled(BadgeComponent)`
  margin-right: ${getSpacings('xs')};
`;

const StyledAvatar = styled(Avatar)`
  height: 16px;
  width: 16px;
`;

const columnHelper = createColumnHelper<Group>();

const columns = [

  columnHelper.accessor('displayName', {
    header: () => (
      <TableHeaderCell span="320px">
        Group
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        displayName,
      } = info.row.original;

      return (
        <TableCell span="320px">
          <Typography size="sm">
            {displayName}
          </Typography>
        </TableCell>
      );
    },
  }),

  columnHelper.accessor('users', {
    header: () => (
      <TableHeaderCell span="288px">
        Users
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        users,
      } = info.row.original;

      const firstTwoUsers = users.items.slice(0, 2);

      return (
        <TableCell span="288px">
          <BadgeContainer>
            {firstTwoUsers.map((items, index ) => (
              <UserBadge
                key={index}
                color="gray"
                text={items.user.displayName || ''}
                startAdornment={(
                  <StyledAvatar
                    variant="icon"
                    icon={{
                      variant: 'user',
                    }}
                  />
                )}
              />
            ))}
            {users.items.length > 2 && (
              <ComponentBadge
                color="gray"
                size="sm"
                text={`+${users.items.length - 2}`}
              />
            )}
          </BadgeContainer>
        </TableCell>
      );
    },
  }),

  columnHelper.accessor('permissions', {
    header: () => (
      <TableHeaderCell size="192px">
        Permissions
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        permissions,
      } = info.row.original;

      const firstTwoPermissions = permissions.slice(0, 2);

      return (
        <TableCell size="192px">
          <BadgeContainer>
            {firstTwoPermissions.map((permission, index) => {
              const permissionValue = TENANT_PERMISSION_LABEL_MAPPER[permission];

              return (
                <ComponentBadge
                  key={index}
                  color="gray"
                  size="sm"
                  text={permissionValue || ''}
                />
              );
            })}

            {permissions.length > 2 && (
              <ComponentBadge
                color="gray"
                size="sm"
                text={`+${permissions.length - 2}`}
              />
            )}
          </BadgeContainer>
        </TableCell>
      );
    },
  }),
];

export type TenantGroupsProps = {
  tenantID: string;
};

const TenantGroupsTable = (props: TenantGroupsProps) => {
  const { tenantID } = props;
  const tenantGroupsQuery = getTenantGroupsQuery(tenantID);

  const initialPageParam: FetchTenantGroupsParams = {
    filter: {
      query: '',
    },
    continuationToken: ''
  };

  const infiniteQuery = useInfiniteQuery({
    ...tenantGroupsQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await tenantGroupsQuery.queryFn(tenantID, data.pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { continuationToken } = lastPage;

      return continuationToken ? {
        continuationToken,
      } : undefined;
    },
  });

  const queryData = infiniteQuery.data;

  const tableData = useMemo(() => {
    if (queryData) {
      return queryData.pages.flatMap((page) => page.items);
    }

    return [];
  }, [queryData?.pages]);

  if (infiniteQuery.isFetched && tableData.length === 0) {

    return (
      <EmptyTable/>
    );
  }

  return (
    <OncoreTable
      columns={columns}
      data={tableData}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 40,
      }}
    />
  );
};

export default TenantGroupsTable;
