import { createColumnHelper } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { EmptyTable, TableCell, TableHeaderCell } from '@oncore/ui/Table';
import Typography from '@oncore/ui/Typography';
import OncoreTable from '@oncore/ui/shared/OncoreTable';
import { getTenantContractsQuery } from './queries';
import { format } from 'date-fns';
import { DATE_API_FORMAT, DATE_UI_FORMAT } from 'src/constants';
import TenantContractWorkerTypeCell from './TenantContractTypeCell';
import { WorkerType } from 'src/api/gql/graphql';
import { ContractsWorkerType } from 'src/types/tenants';
import {
  Contract,
  FetchTenantContractsParams
} from './services';
import AvatarLabelGroup from '@oncore/ui/AvatarLabelGroup';

const columnHelper = createColumnHelper<Contract>();

const columns = [

  columnHelper.accessor('displayName', {
    header: () => (
      <TableHeaderCell span="320px">
        Worker
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        displayName,
        role
      } = info.row.original;

      return (
        <TableCell span="320px">
          <AvatarLabelGroup
            avatar={{
              variant: 'initials',
              displayName,
            }}
            text={displayName}
            supportingText={role}
            size="md"/>
        </TableCell>
      );
    },
  }),

  columnHelper.accessor('workerType', {
    id: 'workerTypeDisplay',
    header: () => (
      <TableHeaderCell span="145px">
        Type
      </TableHeaderCell>
    ),
    cell: (info) => {
      const workerTypeValue: string | undefined = Object.entries(ContractsWorkerType)
        .find(([ , value ]) => value === info.cell.getValue())?.[1];

      return (
        <TenantContractWorkerTypeCell
          span="145px"
          workerType={workerTypeValue || ''}
        />
      );
    }
  }),

  columnHelper.accessor('startDate', {
    id: 'periodDisplay',
    header: () => (
      <TableHeaderCell size="335px">
        Period
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        startDate,
        endDate
      } = info.row.original;

      const formattedStartDate = format(new Date(startDate), DATE_UI_FORMAT);
      const formattedEndDate = format(new Date(endDate), DATE_UI_FORMAT);

      const periods = `${formattedStartDate} / ${formattedEndDate}`;

      return (
        <TableCell size="335px">
          <Typography size="sm">
            {periods}
          </Typography>
        </TableCell>
      );
    },
  }),
];

export type TenantContractsProps = {
  tenantID: string;
};

const TenantContractsTable = (props: TenantContractsProps) => {
  const { tenantID } = props;

  const tenantContractsQuery = getTenantContractsQuery(tenantID);

  const initialPageParam: FetchTenantContractsParams = {
    filter: {
      query: '',
      workerType: WorkerType.All,
      startDate: format(new Date(), DATE_API_FORMAT),
      endDate: format(new Date(), DATE_API_FORMAT),
      endingContracts: false
    },
    continuationToken: ''
  };

  const infiniteQuery = useInfiniteQuery({
    ...tenantContractsQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await tenantContractsQuery.queryFn(tenantID, data.pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { continuationToken } = lastPage;

      return continuationToken ? {
        continuationToken,
      } : undefined;
    },
  });

  const queryData = infiniteQuery.data;

  const tableData = useMemo(() => {
    if (queryData) {
      return queryData.pages.flatMap((page) => page.items);
    }

    return [];
  }, [queryData?.pages]);

  if (infiniteQuery.isFetched && tableData.length === 0) {

    return (
      <EmptyTable/>
    );
  }

  return (
    <OncoreTable
      columns={columns}
      data={tableData}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 73,
      }}
    />
  );
};

export default TenantContractsTable;
