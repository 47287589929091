import styled from 'styled-components';

import { getSpacings } from '@oncore/ui/Theme';
import Button, { ButtonProps } from '@oncore/ui/Button';

export type TenantSettingsFooterProps = Omit<ButtonProps, 'children' | 'size'>;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${getSpacings('2xl')} 20px;
  align-self: stretch;
  padding: ${getSpacings('xl')} 0 ${getSpacings('xl')} 0;
`;

const SettingsPageFooter = (props: TenantSettingsFooterProps) => {

  return (
    <Container>
      <Button
        size="md"
        {...props}>
        Update
      </Button>
    </Container>
  );
};

export default SettingsPageFooter;
