import { GraphQLClient } from 'graphql-request';
import { EnvironmentState } from './state/environment/reducer';

type Environment = EnvironmentState;

let environment: Environment | undefined;

export const fetchEnvironmentVariables = async () => {
  const isDev = import.meta.env.DEV;
  const response = await fetch(`/environment.${isDev ? 'dev.json' : 'json'}`);
  const variables: Environment = await response.json();

  return variables;
};

export const getEnvironmentVariables = async () => {
  environment = environment || await fetchEnvironmentVariables();

  return environment;
};

export const graphQLClient = new GraphQLClient('');

export default environment;
