import { tenantKeys } from '../tenant-details';
import { fetchTenantUsers } from './services';

export const tenantUsersKeys = {
  tenantUsers: (tenantID: string) => [
    ...tenantKeys.tenant(tenantID),
  ],
};

export const getTenantUsersQuery = (tenantID: string) => ({
  queryKey: tenantUsersKeys.tenantUsers(tenantID),
  queryFn: fetchTenantUsers,
});
