import { useEffect } from 'react';
import { clearCurrentUserRoutine } from '../../state/user/reducer.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const auth0context = useAuth0();

  const logout = async () => {
    dispatch(clearCurrentUserRoutine.trigger());
    await auth0context.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  useEffect(() => {
    logout();
  }, []);

  return null;
};

export default LogoutPage;
