import styled from 'styled-components';

import {
  getSpacings,
  getBreakpointMediaQuery,
  useMediaQuery,
} from '@oncore/ui/Theme';
import TenantsTable, { TenantsTableProps } from './TenantsTable';
import TenantsFilter from './TenantsFilter';
import { useTenantsList } from './hooks';

export type TenantsListProps = Pick<TenantsTableProps, 'onTenantClick'>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: ${getSpacings('2xl')};
`;

const StyledTenantsFilter = styled(TenantsFilter)`
  
  ${getBreakpointMediaQuery('max', 'lg')} {
    padding-left: ${getSpacings('2xl')};
    padding-right: ${getSpacings('2xl')};
  }
`;

const TenantsList = (props: TenantsListProps) => {
  const { onTenantClick } = props;

  const isDesktopView = useMediaQuery('desktop');

  const tenantsListContext = useTenantsList();

  const {
    values,
    queryValues,
    setQueryValues,
    resetFilter,
  } = tenantsListContext.queryParams;

  return (
    <Container>
      {
        isDesktopView && (
          <StyledTenantsFilter
            filter={values}
            onFilterChange={setQueryValues}
            onClearIconClick={resetFilter}/>
        )
      }
      <TenantsTable
        filter={queryValues}
        onClearFilterClick={resetFilter}
        onTenantClick={onTenantClick}/>
    </Container>
  );
};

export default TenantsList;
