import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantUsersNavigationContext = {
  params: TenantNavigationContext['params'];
};

const tenantUsersLink = (params: TenantUsersNavigationContext['params']) => {
  return `${tenantLink(params)}/users` as const;
};

export const tenantUsersPath = tenantUsersLink({
  tenantID: ':tenantID',
});

export default tenantUsersLink;
