import {
  updateUserDisplayNameMutation,
  UpdateUserDisplayNameResult
} from './services';
import { QueryClient } from '@tanstack/react-query';
import { userKeys } from './queries';

export const getUpdateUserMutation = (queryClient: QueryClient) => ({
  mutationFn: updateUserDisplayNameMutation,
  onSuccess: (data: UpdateUserDisplayNameResult) => {
    queryClient.setQueryData(userKeys.userDetails(data.id), {
      ...data,
    });
  },
});
