import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

export type User = {
  id: string;
  displayName: string;
  email: string;
};

export type UsersFilter = {
  query: string;
};

export type FetchUsersParams = {
  filter: UsersFilter;
} | {
  continuationToken: string;
};

const usersQueryDocument = graphql(`
  query Users($filter: UsersFilter, $continuationToken: String) {
    users(filter: $filter, continuationToken: $continuationToken) {
      items {
        id
        displayName
        email
      }
      continuationToken
      totalCount
    }
  }
`);

export const fetchUsers = async (params?: FetchUsersParams) => {
  const variables = params || {};
  const result = await graphQLClient.request(usersQueryDocument, variables);

  return result.users;
};
