import {
  useQuery,
} from '@tanstack/react-query';
import styled from 'styled-components';

import {
  getBreakpointMediaQuery,
  getSpacings,
} from '@oncore/ui/Theme';
import Typography from '@oncore/ui/Typography';
import { getTenantFeaturesQuery } from './queries';
import AgencyFeaturesForm from './AgencyFeaturesForm';
import EnterpriseFeatureForm from './EnterpriseFeaturesForm';
import Divider from '@oncore/ui/Divider';

export type TenantFeaturesProps = {
  tenantID: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0 ${getSpacings('xl')} 0 ${getSpacings('xl')};
  }
`;

const TenantFeatures = (props: TenantFeaturesProps) => {
  const { tenantID } = props;

  const query = useQuery(getTenantFeaturesQuery(tenantID));

  if (!query.data) {
    return null;
  }

  const featuresData = query.data;

  const renderFeaturesForm = () => {
    const type = featuresData.__typename;

    switch (type) {
      case 'Agency':
        return (
          <AgencyFeaturesForm
            tenantID={tenantID}
            features={featuresData.agencyFeatures}/>
        );
      case 'Enterprise':
        return (
          <EnterpriseFeatureForm
            tenantID={tenantID}
            features={featuresData.enterpriseFeatures}/>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Typography size="lg" weight="semibold">
        Features
      </Typography>
      <Typography size="sm" weight="regular" color="tertiary">
        Configure the tenant modules, features and functionality.
      </Typography>
      <Divider spacing="2xl" />
      {
        renderFeaturesForm()
      }
    </Container>
  );
};

export default TenantFeatures;
