import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';

export type EnvironmentState = {
  version: string;
  api: string;
  auth: {
    clientID: string;
    audience: string;
    domain: string;
  },
  amplitude?: {
    key: string;
  },
  isReady?: boolean;
};

export type SetEnvironmentRequestPayload = EnvironmentState;

export const setEnvironmentRoutine = createRoutine('SET_ENVIRONMENT', {
  request: (payload: EnvironmentState) => payload,
});

const environmentInitialState: EnvironmentState = {
  version: '',
  api: '',
  auth: {
    clientID: '',
    audience: '',
    domain: '',
  },
  isReady: false,
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState: environmentInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setEnvironmentRoutine.REQUEST, (_, action: PayloadAction<SetEnvironmentRequestPayload>) => {
      return { ...action.payload, isReady: true };
    })
      .addDefaultCase(() => {});
  }
});

export default environmentSlice.reducer;
