import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import s from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getRadii as b, getPaletteBorder as p, getPaletteBackground as m, getBreakpointMediaQuery as x, getSpacings as c } from "../Theme/utils.es.js";
import { forwardRef as h } from "react";
import "../CssBaseline/index.es.js";
import { T as $, u as f } from "../../hooks-LBznVG2w.js";
import "../../styles-YtnfNTLW.js";
import u from "../Typography/index.es.js";
import { N as y } from "../../ErrorFeedbackContainer-zSGLd02a.js";
import j from "../Button/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../Button/styles.es.js";
const w = s.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
  box-sizing: border-box;
  
  border-radius: ${b("xl")};
  border: 1px solid ${p("secondary")};
  background: ${m("primary.default")};
  
  ${x("max", "sm")} {
    border-radius: 0;
  }
`, T = (e) => {
  const {
    children: t,
    className: r
  } = e;
  return /* @__PURE__ */ o.jsx($, { children: /* @__PURE__ */ o.jsx(w, { className: r, children: t }) });
}, C = s.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`, v = h(function(t, r) {
  const {
    className: n,
    children: l
  } = t, { headerRef: i } = f(), a = (d) => {
    i.current && (i.current.scrollLeft = d.currentTarget.scrollLeft);
  };
  return /* @__PURE__ */ o.jsx(
    C,
    {
      className: n,
      ref: r,
      onScroll: a,
      children: l
    }
  );
}), k = s.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  min-width: 0;
  overflow: hidden;
  
  flex: ${(e) => e.$flex};

  padding: ${c("xl")} ${c("3xl")};
  border-bottom: 1px solid ${p("secondary")};
  
`, R = (e) => {
  const {
    className: t,
    span: r = "1",
    minWidth: n = "0",
    size: l = "0",
    children: i
  } = e, a = `${r} ${n} ${l}`;
  return /* @__PURE__ */ o.jsx(
    k,
    {
      className: t,
      $flex: a,
      children: i
    }
  );
}, B = s.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow-x: hidden;
`, ae = (e) => {
  const { headerRef: t } = f();
  return /* @__PURE__ */ o.jsx(B, { ref: t, children: e.children });
}, N = s(R)`
  padding: ${c("lg")} ${c("3xl")};
  background: ${m("secondary.default")};
`, ce = (e) => {
  const {
    children: t,
    ...r
  } = e;
  return /* @__PURE__ */ o.jsx(N, { ...r, children: typeof t == "string" ? /* @__PURE__ */ o.jsx(
    u,
    {
      color: "tertiary",
      size: "xs",
      weight: "medium",
      children: t
    }
  ) : t });
}, z = s.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  
  &:hover {
    > div {
      background: ${m("primary.hover")};
      cursor: ${(e) => e.$isClickable ? "pointer" : "default"};
    }
  }
`, S = h(function(t, r) {
  const {
    className: n,
    children: l,
    isClickable: i,
    ...a
  } = t;
  return /* @__PURE__ */ o.jsx(
    z,
    {
      ref: r,
      className: n,
      $isClickable: i,
      ...a,
      children: l
    }
  );
}), F = s.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
  gap: ${c("3xl")};
`, P = s(y)`
  width: 352px;
  height: unset;

  ${x("max", "lg")} {
    width: 100%;
  }
`, de = (e) => {
  const {
    title: t,
    hasFilter: r,
    onClearFilterClick: n,
    className: l
  } = e, i = r ? "Adjust your search criteria or clear the filters and try again." : "It's looking a bit empty here.";
  return /* @__PURE__ */ o.jsx(T, { children: /* @__PURE__ */ o.jsxs(F, { className: l, children: [
    /* @__PURE__ */ o.jsx(
      P,
      {
        title: t,
        message: i
      }
    ),
    r && /* @__PURE__ */ o.jsx(
      j,
      {
        variant: "outlined",
        color: "gray",
        onClick: n,
        children: /* @__PURE__ */ o.jsx(u, { size: "md", weight: "semibold", color: "secondary", children: "Clear filters" })
      }
    )
  ] }) });
}, L = s.div`
  flex: 1 1 0;
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
`, V = s(v)`
  position: relative;
  height: ${(e) => e.$height}px;
`, me = h(
  function(t, r) {
    const {
      totalHeight: n,
      children: l,
      className: i
    } = t, { headerRef: a } = f(), d = (g) => {
      a.current && (a.current.scrollLeft = g.currentTarget.scrollLeft);
    };
    return /* @__PURE__ */ o.jsx(
      L,
      {
        ref: r,
        className: i,
        onScroll: d,
        children: /* @__PURE__ */ o.jsx(V, { $height: n, children: l })
      }
    );
  }
), E = s(S).attrs((e) => ({
  style: {
    transform: `translateY(${e.$start}px)`
  }
}))`
  position: absolute;
  width: 100%;
`, he = (e) => {
  const {
    children: t,
    offset: r,
    index: n,
    measureElement: l,
    ...i
  } = e;
  return /* @__PURE__ */ o.jsx(
    E,
    {
      ref: (a) => l(a),
      $start: r,
      "data-index": n,
      ...i,
      children: t
    }
  );
};
export {
  de as EmptyTable,
  T as Table,
  v as TableBody,
  R as TableCell,
  ae as TableHeader,
  ce as TableHeaderCell,
  S as TableRow,
  me as VirtualizedTableBody,
  he as VirtualizedTableRow,
  T as default
};
