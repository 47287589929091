import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useMemo } from 'react';
import routes from 'src/pages/routes.tsx';

const AppRouter = () => {
  const router = useMemo(() => {
    return createBrowserRouter(routes);
  }, []);

  return (
    <RouterProvider router={router}/>
  );
};

export default AppRouter;
