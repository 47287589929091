import { RouteObject } from 'react-router-dom';

import UserDetailsPage from './UserDetailsPage';

import { userDetailsPath } from './link';

const userDetailsRoute: RouteObject = {
  path: userDetailsPath,
  element: <UserDetailsPage/>,
};

export default userDetailsRoute;
