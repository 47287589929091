import { j as e } from "../../jsx-runtime-NmMXPjeo.js";
import r from "styled-components";
import { r as b } from "../../polished.esm-wTGEn2h0.js";
import { useRef as j } from "react";
import { o as C } from "../../index.module-6I51pdhn.js";
import { sizeStyles as v, switchOnContainerKeyFrame as I, switchOffContainerKeyFrame as S, buttonSizes as z, switchOnButtonKeyFrame as F, switchOffButtonKeyFrame as A } from "./styles.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as m, getRadii as g, getPaletteColors as l, getPaletteBackground as d, getPaletteForeground as $, getEffects as B } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import { I as E } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import { u as M } from "../../useIsMounted-8GJRhVk0.js";
import "@floating-ui/react-dom";
import "../../styles-YtnfNTLW.js";
import O from "../Typography/index.es.js";
import { FieldControlLabel as P } from "../FieldControl/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../Icon/variants.es.js";
import "../../clsx.m-7d8NmkQq.js";
const T = r.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: none;
  box-sizing: border-box;
  
  ${(o) => v[o.$size]};
  
  padding: ${m("xxs")};
  border-radius: ${g("full")};

  animation-timing-function: ease-in-out;
  
  animation-duration: ${(o) => o.$isAnimationEnabled ? "0.4s" : "0s"};
  
  &[aria-checked="true"] {
    animation-name: ${I};
    background: ${(o) => l(o.$color)(o)[600]};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => b(l(o.$color)(o)[500], 0.2)};
    }
    
    &:hover:not(:focus):not(:disabled) {
      background: ${(o) => l(o.$color)(o)[700]};
    }

    &[aria-invalid="true"] {
      background-color: ${d("error.solid")};
    }
  }

  &[aria-checked="false"] {
    animation-name: ${S};
    background: ${d("tertiary")};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => b(l("gray.400")(o), 0.2)};
    }

    &[aria-invalid="true"] {
      background-color: ${d("error.secondary")};
    }
  }
  
  &:disabled {
    background: ${d("disabled.default")};
  }
`, K = r.input`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed;
  }
`, N = 400, R = r.span`
  border: none;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  ${(o) => z[o.$size]};
  
  background-color: ${$("white")};
  border-radius: ${g("full")};
  box-shadow: ${B("shadows.sm")};
  
  animation-timing-function: ease-in-out;
  
  animation-duration: ${(o) => o.$isAnimationEnabled ? "0.3s" : "0s"};
  
  &[aria-checked="true"] {
    left: calc(50%);

    animation-name: ${F};
  }

  &[aria-checked="false"] {
    left: 2px;

    animation-name: ${A};
  }
  
`, _ = r.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`, L = r(E)`
  color: ${$("error.secondary")};
`, D = (o) => {
  const {
    size: t = "md",
    color: s = "brand",
    checked: i,
    onChange: a,
    onFocus: n,
    isInvalid: p,
    ...u
  } = o, h = j(null), y = M(), [f] = C(y, N), k = (c) => {
    a && a(c, c.target.checked);
  }, w = (c) => {
    var x;
    n && n(c), (x = h.current) == null || x.focus();
  };
  return /* @__PURE__ */ e.jsxs(
    T,
    {
      ref: h,
      $size: t,
      $color: s,
      $isAnimationEnabled: f,
      "aria-checked": i,
      "aria-invalid": p,
      disabled: u.disabled,
      children: [
        /* @__PURE__ */ e.jsx(
          K,
          {
            type: "checkbox",
            checked: i,
            onChange: k,
            onFocus: w,
            ...u
          }
        ),
        /* @__PURE__ */ e.jsx(
          R,
          {
            $size: t,
            $isAnimationEnabled: f,
            "aria-checked": i,
            children: p && /* @__PURE__ */ e.jsx(_, { children: /* @__PURE__ */ e.jsx(
              L,
              {
                variant: "alert-circle",
                width: 14,
                height: 14
              }
            ) })
          }
        )
      ]
    }
  );
}, U = r.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: ${m("xxs")};
`, Y = (o) => {
  const {
    label: t,
    helperMessage: s,
    errorMessage: i
  } = o, a = !!i, n = i || s;
  return /* @__PURE__ */ e.jsxs(U, { children: [
    typeof t == "string" ? /* @__PURE__ */ e.jsx(P, { text: t }) : t,
    !!n && /* @__PURE__ */ e.jsx(
      O,
      {
        size: "sm",
        color: a ? "error" : void 0,
        children: n
      }
    )
  ] });
}, q = r.div`
  display: flex;
  width: 344px;
  
  gap: ${m("md")};
`, wo = (o) => {
  const {
    label: t,
    helperMessage: s,
    errorMessage: i,
    ...a
  } = o, n = !!i;
  return /* @__PURE__ */ e.jsxs(q, { children: [
    /* @__PURE__ */ e.jsx(
      D,
      {
        isInvalid: n,
        ...a
      }
    ),
    /* @__PURE__ */ e.jsx(
      Y,
      {
        label: t,
        helperMessage: s,
        errorMessage: i
      }
    )
  ] });
};
export {
  D as BaseSwitch,
  Y as SwitchControlLayout,
  wo as default
};
