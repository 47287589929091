import { TenantSettings } from 'src/features/tenant-settings';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from '../Tenant';

const TenantSettingsPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantSettings tenantID={params.tenantID}/>
  );
};

export default TenantSettingsPage;
