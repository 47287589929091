import { TenantUsersTable } from 'src/features/tenant-users';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantUsersNavigationContext } from './link';

const TenantUsersPage = () => {
  const { params } = useNavigationContext<TenantUsersNavigationContext>();

  return (
    <TenantUsersTable tenantID={params.tenantID}/>
  );
};

export default TenantUsersPage;
