import { useQuery } from '@tanstack/react-query';

import { getTenantSettingsQuery } from './queries.ts';
import SettingsPage from './SettingsPage';

export type TenantSettingsProps = {
  tenantID: string;
};

const TenantSettings = (props: TenantSettingsProps) => {
  const { tenantID } = props;

  const query = useQuery(getTenantSettingsQuery(tenantID));

  if (!query.data || query.isRefetching || query.data.tenant.__typename === 'Agency') {
    return null;
  }

  const tenant = query.data.tenant;
  const reference = query.data.reference;

  const settings = {
    isCostCenterFieldRequired: tenant.isCostCenterFieldRequired,
    isLineManagerRequired: tenant.isLineManagerRequired,
    isProjectFieldRequired: tenant.isProjectFieldRequired,
    isTimesheetApproversRequired: tenant.isTimesheetApproversRequired,
    workCoverIndustryClassificationCodeID: tenant.wicCodeID,
  };

  return (
    <SettingsPage
      tenantID={tenantID}
      settings={settings}
      features={query.data.tenant.features}
      wicCodes={reference.workCoverIndustryCodes.items}
    />
  );
};

export default TenantSettings;
