import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import PageContent, {
  PageContentHeader,
} from '@oncore/ui/PageContent';
import Icon from '@oncore/ui/Icon';
import IconButton from '@oncore/ui/IconButton';
import {
  useMediaQuery,
} from '@oncore/ui/Theme';
import Screen, {
  ScreenHeader,
  ScreenBody,
} from '@oncore/ui/Screen';

import {
  TenantsList,
  TenantsFilter,
  TenantsListProvider,
  useTenantsList,
  TenantsListProps,
} from 'src/features/tenants';
import links from 'src/pages/links';
import { useDisclose } from '@oncore/ui/hooks';

const StyledPageContentHeader = styled(PageContentHeader)`
  justify-content: space-between;
`;

const StyledTenantsFilter = styled(TenantsFilter)`
  flex: 1;
`;

const TenantsPage = () => {
  const isMobileView = useMediaQuery('mobile');

  const { queryParams } = useTenantsList();

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const navigate = useNavigate();

  const handleTenantClick: TenantsListProps['onTenantClick'] = (tenant) => {
    navigate(links.tenant({
      tenantID: tenant.id,
    }));
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    
    queryParams.resetFilter();
  };

  return (
    <PageContent>
      <StyledPageContentHeader title="Tenants">
        {
          isMobileView && (
            <IconButton
              variant="ghost"
              color="gray"
              size="md"
              onClick={onOpen}>
              <Icon variant="search-lg"/>
            </IconButton>
          )
        }
      </StyledPageContentHeader>
      <TenantsList onTenantClick={handleTenantClick}/>
      <Screen
        isOpen={isMobileView && isOpen}
        onClose={handleClose}>
        <ScreenHeader>
          <StyledTenantsFilter
            filter={queryParams.values}
            onFilterChange={queryParams.setQueryValues}
            onClearIconClick={queryParams.resetFilter}/>
        </ScreenHeader>
        <ScreenBody>
          <TenantsList onTenantClick={handleTenantClick}/>
        </ScreenBody>
      </Screen>
    </PageContent>
  );
};

const TenantsPageWithProvider = () => {
  return (
    <TenantsListProvider>
      <TenantsPage/>
    </TenantsListProvider>
  );
};

export default TenantsPageWithProvider;
