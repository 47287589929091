import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

const fetchTenantDetailsQueryDocument = graphql(`
  query Tenant($tenantID: ID!) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        __typename
        id
        displayName
        region {
          displayName
          code
        }
        company {
          displayName
        }
      }
    ... on Agency {
        __typename
        id
        displayName
        region {
          displayName
          code
        }
        company {
          displayName
        }
        vendor {
          id
          displayName
        }
      }
    }
  }
`);


export const fetchTenantDetails = async (tenantID: string) => {
  const { tenant } = await graphQLClient.request(fetchTenantDetailsQueryDocument, {
    tenantID
  });

  return {
    ...tenant,
    company: {
      displayName: tenant.company.displayName || '',
    },
  };
};
