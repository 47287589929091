import { RouteObject } from 'react-router-dom';

import { tenantContractsPath } from './link';
import TenantContractsPage from './TenantContractsPage';

const tenantContractsRoute: RouteObject = {
  path: tenantContractsPath,
  element: <TenantContractsPage/>,
};

export default tenantContractsRoute;
