import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantTimesheetsNavigationContext = TenantNavigationContext;

const tenantTimesheetsLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/timesheets` as const;
};

export const tenantTimesheetsPath = tenantTimesheetsLink({
  tenantID: ':tenantID',
});

export default tenantTimesheetsLink;
