import * as yup from 'yup';

import {
  EnterpriseFeature,
  AgencyFeature
} from 'src/types/tenants';

export const updateAgencyFeaturesSchema = yup.object({
  tenantID: yup.string().required(),
  features: yup.array(
    yup.mixed<AgencyFeature>().required()
  ).required(),
});

export const updateEnterpriseFeaturesSchema = yup.object({
  tenantID: yup.string().required(),
  features: yup.array(
    yup.mixed<EnterpriseFeature>().required()
  ).required(),
});
