import { RouteObject } from 'react-router-dom';

import TenantSettingsPage from './TenantSettingsPage';

import { tenantSettingsPath } from './link';

const tenantSettingsRoute: RouteObject = {
  path: tenantSettingsPath,
  element: <TenantSettingsPage/>,
};

export default tenantSettingsRoute;
