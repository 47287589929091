import {
  createContext,
  PropsWithChildren,
} from 'react';
import {
  useQueryParameters,
  UseQueryParametersContext,
} from '@oncore/ui/shared/router';

import {
  DEFAULT_FILTER,
  CONTEXT_MAPPER,
} from './constants';

export type UsersContext = {
  queryParams: UseQueryParametersContext<typeof CONTEXT_MAPPER> & {
    resetFilter: () => void;
  };
};

export type UsersListProviderProps = PropsWithChildren<{
  defaultFilter?: typeof DEFAULT_FILTER;
}>;

export const UsersContext = createContext({} as UsersContext);

const UsersListProvider = (props: UsersListProviderProps) => {
  const {
    children,
    defaultFilter = DEFAULT_FILTER,
  } = props;

  const queryParams = useQueryParameters({
    contextMapper: CONTEXT_MAPPER,
  });

  const handleResetFilter = () => {
    queryParams.setQueryValues(defaultFilter);
  };

  return (
    <UsersContext.Provider value={{
      queryParams: {
        ...queryParams,
        resetFilter: handleResetFilter,
      },
    }}>
      {children}
    </UsersContext.Provider>
  );
};

export default UsersListProvider;
