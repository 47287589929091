import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantSettingsNavigationContext = TenantNavigationContext;

const tenantSettingsLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/settings` as const;
};

export const tenantSettingsPath = tenantSettingsLink({
  tenantID: ':tenantID',
});

export default tenantSettingsLink;
