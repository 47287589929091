import { fetchUserDetails } from './services';

export const userKeys = {
  user: (userID: string) => [
    'user',
    userID,
  ] as const,
  userDetails: (userID: string) => [
    ...userKeys.user(userID),
    'details'
  ] as const,
};

export const getUserDetailsQuery = (userID: string) => ({
  queryKey: userKeys.userDetails(userID),
  queryFn: () => fetchUserDetails(userID),
});
