import {
  matchPath,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import PageContent, {
  PageContentHeader,
} from '@oncore/ui/PageContent';
import TabControl, {
  TabList,
} from '@oncore/ui/TabControl';
import {
  getBreakpointMediaQuery,
  getSpacings,
  useMediaQuery,
} from '@oncore/ui/Theme';
import { getUserDetailsQuery } from 'src/features/user-details';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { UserNavigationContext } from './link';
import links from '../links';
import Screen, {
  ScreenHeader,
  ScreenBody,
} from '@oncore/ui/Screen';
import Avatar from '@oncore/ui/Avatar';
import Typography from '@oncore/ui/Typography';

type UserTabsContext = {
  userID: string;
  currentPath: string;
};

const createTabsContext = (context: UserTabsContext) => {
  const {
    userID,
    currentPath,
  } = context;

  const defaultParams = {
    userID,
  };

  const defaultTabs = [
    {
      label: 'Details',
      link: links.userDetails(defaultParams),
    },
    {
      label: 'Activity',
      link: '/activity',
      disabled: true,
    },
    {
      label: 'Features',
      link: '/features',
      disabled: true,
    },
    {
      label: 'Permissions',
      link: '/permissions',
      disabled: true,
    },
    {
      label: 'Tenants',
      link: '/tenants',
      disabled: true,
    },
  ];

  const tabs = defaultTabs.map(({
    label,
    disabled,
  }) => ({
    label,
    disabled,
  }));

  const defaultTabIndex = defaultTabs.findIndex((tab) => {
    return matchPath({
      path: tab.link,
      end: false,
    }, currentPath);
  });

  return {
    defaultTabs,
    tabs,
    defaultTabIndex: defaultTabIndex === -1 ? 0 : defaultTabIndex,
  };
};

const StyledTabControl = styled(TabControl)`
  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0px ${getSpacings('xl')} 0px ${getSpacings('xl')};
  }
`;

const StyledPageContent = styled(PageContent)`
  width: 100%;
`;

const UserHeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserPage = () => {
  const {
    params,
    location,
  } = useNavigationContext<UserNavigationContext>();

  const navigate = useNavigate();

  const query = useQuery(getUserDetailsQuery(params.userID));

  const tabsContext = useMemo(() => {

    if (!query.data) {
      return {
        defaultTabIndex: 0,
        defaultTabs: [],
        tabs: [],
      };
    }

    return createTabsContext({
      userID: params.userID,
      currentPath: location.pathname,
    });
  }, [
    params.userID,
    query.data,
  ]);

  const isMobileView = useMediaQuery('mobile');

  const handleTabIndexChange = (index: number) => {
    const selectedTab = tabsContext.defaultTabs[index];

    navigate(selectedTab.link);
  };

  const handleScreenClose = () => {
    navigate(links.users());
  };

  if (!query.data) {
    return null;
  }

  const userData = query.data;

  const Content = (
    <StyledPageContent>
      <PageContentHeader title="">
        <Avatar
          variant="initials"
          displayName={userData.displayName || ''}
          size="md"/>
        <UserHeaderDetails>
          <Typography size="md" weight="semibold" color="primary">
            {userData.displayName}
          </Typography>
          <Typography size="xs" weight="regular" color="tertiary">
            {userData.email}
          </Typography>
        </UserHeaderDetails>
      </PageContentHeader>
      <StyledTabControl
        variant="shadow"
        color="gray"
        defaultIndex={tabsContext.defaultTabIndex}
        onChange={handleTabIndexChange}>
        <TabList tabs={tabsContext.tabs}/>
      </StyledTabControl>
      <Outlet/>
    </StyledPageContent>
  );

  if (isMobileView) {
    return (
      <Screen
        isOpen={isMobileView}
        onClose={handleScreenClose}>
        <ScreenHeader/>
        <ScreenBody>
          {Content}
        </ScreenBody>
      </Screen>
    );
  }

  return Content;
};

export default UserPage;
