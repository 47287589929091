import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantFeaturesNavigationContext = TenantNavigationContext;

const tenantFeaturesLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/features` as const;
};

export const tenantFeaturesPath = tenantFeaturesLink({
  tenantID: ':tenantID',
});

export default tenantFeaturesLink;
