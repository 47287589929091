import { put, takeLatest, select } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { SetUserTriggerPayload, clearCurrentUserRoutine, setTokenRoutine, setUserRoutine } from './reducer';
import { getCurrentUserSelector } from './selectors';
import { graphQLClient } from 'src/environment';

function* setUser(action: PayloadAction<SetUserTriggerPayload>) {
  yield* put(setUserRoutine.request(action.payload));
}

function* clearCurrentUser() {
  yield* put(clearCurrentUserRoutine.request());
  yield* put(clearCurrentUserRoutine.success());
}

function* setToken(action: PayloadAction<string>) {
  yield* put(setTokenRoutine.request(action.payload));
  yield* put(setTokenRoutine.success());
}

function* setGraphQLClientHeader() {
  const { token } = yield* select(getCurrentUserSelector());
  const authValue = token ? `Bearer ${token}` : '';

  graphQLClient.setHeader('Authorization', authValue);
}

export default function* userSagas() {
  yield* takeLatest(setUserRoutine.TRIGGER, setUser);
  yield* takeLatest(clearCurrentUserRoutine.TRIGGER, clearCurrentUser);
  yield* takeLatest(setTokenRoutine.TRIGGER, setToken);
  yield* takeLatest([ clearCurrentUserRoutine.SUCCESS, setTokenRoutine.SUCCESS, ], setGraphQLClientHeader);
}
