import usersLink from '../Users/link';

export type UserNavigationContext = {
  params: {
    userID: string;
  };
};

const userLink = (params: UserNavigationContext['params']) => {
  return `${usersLink()}/${params.userID}` as const;
};

export const userPath = userLink({
  userID: ':userID',
});

export default userLink;
