import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export type SetUserTriggerPayload = {
  name: string;
  email: string;
  avatarUrl: string;
  isEmailVerified?: boolean;
};

export type SetUserRequestPayload = SetUserTriggerPayload;

export type FailurePayload = {
  message: string;
};

type UserState = {
  currentUser: {
    name: string;
    email: string;
    avatarUrl: string;
    token: string;
    isEmailVerified?: boolean;
  };
};

export const setUserRoutine = createRoutine('SET_USER', {
  trigger: (payload: SetUserTriggerPayload) => payload,
  request: (payload: SetUserRequestPayload) => payload,
  failure: (payload: FailurePayload) => payload,
});
export const setUserPromiseCreator = promisifyRoutine(setUserRoutine);

export const clearCurrentUserRoutine = createRoutine('CLEAR_CURRENT_USER', {
  failure: (payload: FailurePayload) => payload,
});

export const setTokenRoutine = createRoutine('SET_TOKEN', {
  trigger: (tokenPayload: string) => tokenPayload,
  request: (tokenPayload: string) => tokenPayload,
  failure: (payload: FailurePayload) => payload,
});

const initialState: UserState = {
  currentUser: {
    name: '',
    email: '',
    avatarUrl: '',
    token: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearCurrentUser: (state) => {
      state.currentUser = initialState.currentUser;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.currentUser.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setUserRoutine.REQUEST,
        (state, action: PayloadAction<SetUserRequestPayload>) => {
          const { name, email, isEmailVerified, avatarUrl } = action.payload;

          state.currentUser = {
            name,
            email,
            avatarUrl,
            isEmailVerified,
            token: state.currentUser.token,
          };
        }
      )
      .addCase(
        clearCurrentUserRoutine.REQUEST,
        (state) => {
          state.currentUser = initialState.currentUser;
        }
      )
      .addCase(setTokenRoutine.REQUEST, (state, action: PayloadAction<string>) => {
        state.currentUser.token = action.payload;
      })
      .addDefaultCase(() => {});
  },
});

export default userSlice.reducer;
