import { useSelector } from 'react-redux';
import TopBar from '@oncore/ui/TopBar';
import ChromeDropdownItems from './ChromeDropdownItems.tsx';
import ChromeLeftAlignedTopBarItems from './ChromeLeftAlignedTopBarItems.tsx';
import ChromeRightAlignedTopBarItems from './ChromeRightAlignedTopBarItems.tsx';
import { getCurrentUserSelector } from 'src/state/user/selectors.ts';

const ChromeTopBar = () => {
  const currentUser = useSelector(getCurrentUserSelector());

  return (
    <TopBar
      product="omp"
      userLoginEmail={currentUser.email}
      userDisplayName={currentUser.name}
      dropdownChildren={(<ChromeDropdownItems />)}
      leftAlignedChildren={(<ChromeLeftAlignedTopBarItems />)}
      rightAlignedChildren={(<ChromeRightAlignedTopBarItems />)}
    />
  );
};

export default ChromeTopBar;
