import { createColumnHelper } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { EmptyTable, TableCell, TableHeaderCell } from '@oncore/ui/Table';
import Typography from '@oncore/ui/Typography';
import OncoreTable from '@oncore/ui/shared/OncoreTable';
import { TimesheetStatus } from 'src/types/tenants';
import {
  FetchTenantTimesheetsParams,
  Timesheet,
} from './services';
import { getTenantTimesheetsQuery } from './queries';
import TenantTimesheetStatusCell from './TenantTimesheetStatusCell';
import { format } from 'date-fns';
import { DATE_UI_FORMAT } from 'src/constants';
import AvatarLabelGroup from '@oncore/ui/AvatarLabelGroup';

const columnHelper = createColumnHelper<Timesheet>();

const columns = [

  columnHelper.accessor('contract.displayName', {
    header: () => (
      <TableHeaderCell span="320px">
        Worker
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        worker,
      } = info.row.original;

      return (
        <TableCell span="320px">
          <AvatarLabelGroup
            avatar={{
              variant: 'initials',
              displayName: worker.displayName || '',
            }}
            text={worker.displayName || ''}
            supportingText={info.getValue() || ''}
            size="md"/>
        </TableCell>
      );
    },
  }),

  columnHelper.accessor('status', {
    id: 'statusDisplay',
    header: () => (
      <TableHeaderCell span="145px">
        Status
      </TableHeaderCell>
    ),
    cell: (info) => {
      const statusValue: string | undefined = Object.entries(TimesheetStatus)
        .find(([key]) => key === info.cell.getValue())?.[1];
      
      return (
        <TenantTimesheetStatusCell
          span="145px"
          status={statusValue || ''}
        />
      );
    }
  }),

  columnHelper.accessor('startDate', {
    id: 'periodDisplay',
    header: () => (
      <TableHeaderCell size="335px">
        Period
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        startDate,
        endDate
      } = info.row.original;

      const formattedStartDate = format(new Date(startDate), DATE_UI_FORMAT);
      const formattedEndDate = format(new Date(endDate), DATE_UI_FORMAT);

      const periods = `${formattedStartDate} / ${formattedEndDate}`;

      return (
        <TableCell size="335px">
          <Typography size="sm">
            {periods}
          </Typography>
        </TableCell>
      );
    },
  }),
];

export type TenantTimesheetsProps = {
  tenantID: string;
};

const TenantTimesheetsTable = (props: TenantTimesheetsProps) => {
  const { tenantID } = props;
  const tenantTimesheetsQuery = getTenantTimesheetsQuery(tenantID);

  const initialPageParam: FetchTenantTimesheetsParams = {
    filter: {
      query: '',
    },
    continuationToken: ''
  };

  const infiniteQuery = useInfiniteQuery({
    ...tenantTimesheetsQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await tenantTimesheetsQuery.queryFn(tenantID, data.pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { continuationToken } = lastPage;

      return continuationToken ? {
        continuationToken,
      } : undefined;
    },
  });

  const queryData = infiniteQuery.data;

  const tableData = useMemo(() => {
    if (queryData) {
      return queryData.pages.flatMap((page) => page.items);
    }

    return [];
  }, [queryData?.pages]);

  if (infiniteQuery.isFetched && tableData.length === 0) {

    return (
      <EmptyTable/>
    );
  }

  return (
    <OncoreTable
      columns={columns}
      data={tableData}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 73,
      }}
    />
  );
};

export default TenantTimesheetsTable;
