import styled from 'styled-components';
import PageContent, {
  PageContentHeader,
} from '@oncore/ui/PageContent';
import { useMediaQuery } from '@oncore/ui/Theme';
import {
  UsersFilter,
  UsersList,
  UsersListProvider
} from 'src/features/users';
import Icon from '@oncore/ui/Icon';
import IconButton from '@oncore/ui/IconButton';
import Screen, {
  ScreenHeader,
  ScreenBody,
} from '@oncore/ui/Screen';
import { useUsersList } from 'src/features/users/hooks';
import { useDisclose } from '@oncore/ui/hooks';
import links from '../links';
import { useNavigate } from 'react-router-dom';
import { UsersListProps } from 'src/features/users/UsersList';

const StyledPageContentHeader = styled(PageContentHeader)`
  justify-content: space-between;
`;

const StyledUsersFilter = styled(UsersFilter)`
  flex: 1;
`;

const UserPage = () => {
  const isMobileView = useMediaQuery('mobile');
  const { queryParams } = useUsersList();

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const navigate = useNavigate();
  const handleUserClick: UsersListProps['onUserClick'] = (user) => {
    navigate(links.user({
      userID: user.id,
    }));
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    queryParams.resetFilter();
  };

  return (
    <PageContent>
      <StyledPageContentHeader title="Users">
        {
          isMobileView && (
            <IconButton
              variant="ghost"
              color="gray"
              size="md"
              onClick={onOpen}>
              <Icon variant="search-lg" />
            </IconButton>
          )
        }
      </StyledPageContentHeader>
      <UsersList onUserClick={handleUserClick}/>
      <Screen
        isOpen={isMobileView && isOpen}
        onClose={handleClose}>
        <ScreenHeader>
          <StyledUsersFilter
            filter={queryParams.values}
            onFilterChange={queryParams.setQueryValues}
            onClearIconClick={queryParams.resetFilter} />
        </ScreenHeader>
        <ScreenBody>
          <UsersList />
        </ScreenBody>
      </Screen>
    </PageContent>
  );
};

const UsersPageWithProvider = () => {
  return (
    <UsersListProvider>
      <UserPage/>
    </UsersListProvider>
  );
};

export default UsersPageWithProvider;
