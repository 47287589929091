import { RouteObject } from 'react-router-dom';

import LogoutPage from './LogoutPage.tsx';
import logoutLink from './link';

const logoutRoute: RouteObject = {
  path: logoutLink(),
  element: <LogoutPage />,
};

export default logoutRoute;
