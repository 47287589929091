import { Navigate } from 'react-router-dom';

import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { UserNavigationContext } from './link';

const UserIndexPage = () => {
  const { params } = useNavigationContext<UserNavigationContext>();

  return (
    <Navigate
      to={`/users/${params.userID}/details`}
      replace={true}/>
  );
};

export default UserIndexPage;
