import { RouteObject } from 'react-router-dom';

import TenantUsersPage from './TenantUsersPage';
import { tenantUsersPath } from './link';

const tenantUsersRoute: RouteObject = {
  path: tenantUsersPath,
  element: <TenantUsersPage/>,
};

export default tenantUsersRoute;
