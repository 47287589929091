import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantDetailsNavigationContext = TenantNavigationContext;

const tenantDetailsLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/details` as const;
};

export const tenantDetailsPath = tenantDetailsLink({
  tenantID: ':tenantID',
});

export default tenantDetailsLink;
