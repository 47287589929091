import { j as e } from "./jsx-runtime-NmMXPjeo.js";
import l from "styled-components";
import { forwardRef as B, useState as L, useEffect as U, useRef as k } from "react";
import "./components/Theme/themes.es.js";
import "./palettes-foWluF5S.js";
import { getPaletteBase as W, getSpacings as m, getRadii as A, getPaletteBackground as E, getPaletteForeground as M, getPaletteBorder as q, getEffects as z } from "./components/Theme/utils.es.js";
import "./components/CssBaseline/index.es.js";
import { Menu as I } from "@headlessui/react";
import { createPortal as G } from "react-dom";
import "./styles-YtnfNTLW.js";
import T from "./components/Typography/index.es.js";
import { I as K } from "./Icon-qMvz1KAz.js";
import "./components/Icon/countryVariants.es.js";
import { BaseTextField as J } from "./components/TextField/index.es.js";
import { u as Q } from "./useAppFloating-h0t839w5.js";
const X = l(T)`
  flex: 1;
  text-align: left;
`, Y = (t) => /* @__PURE__ */ e.jsx(
  X,
  {
    size: "md",
    weight: "medium",
    ...t
  }
), Z = l.button`
  display: inline-flex;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  
  background: ${W("transparent")};
  padding: 10px 10px 10px ${m("md")};
  gap: ${m("md")};
  border-radius: ${A("sm")};
  
  &:hover {
    background: ${E("active")};
  }
  
  &[aria-disabled="true"] {
    cursor: not-allowed;
    background: ${E("disabled.subtle")};
  }
`, _ = l(K)`
  color: ${M("brand.primary.default")};
`, ee = (t) => {
  const {
    isSelected: a,
    children: o,
    ...i
  } = t;
  return /* @__PURE__ */ e.jsx(
    I.Item,
    {
      as: Z,
      ...i,
      children: /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
        typeof o == "string" ? /* @__PURE__ */ e.jsx(
          Y,
          {
            color: t.disabled ? "disabled" : void 0,
            children: o
          }
        ) : o,
        a && /* @__PURE__ */ e.jsx(
          _,
          {
            width: 20,
            height: 20,
            variant: "check"
          }
        )
      ] })
    }
  );
}, te = l.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`, ne = l(I.Items)`
  box-sizing: border-box;
  max-height: 320px;
  overflow-y: auto;
  
  width: ${(t) => t.$width ? `${t.$width}px` : "initial"};
  
  padding: ${m("xs")} ${m("sm")};
  background: ${E("primary.default")};
  border-radius: ${A("md")};
  border: 1px solid ${q("secondary")};
  box-shadow: ${z("shadows.lg")};

  margin-top: ${m("xs")};
  margin-bottom: ${m("xs")};
`, oe = B(function(a, o) {
  const {
    textFieldRef: i,
    children: c,
    ...d
  } = a, [
    f,
    s
  ] = L(0);
  return U(() => {
    var x;
    const h = (x = i == null ? void 0 : i.current) == null ? void 0 : x.parentElement;
    if (!h)
      return;
    const y = new ResizeObserver((b) => {
      const [g] = b, [$] = g.borderBoxSize;
      s($.inlineSize);
    });
    return y.observe(h), () => {
      y.disconnect();
    };
  }, []), /* @__PURE__ */ e.jsx(
    ne,
    {
      ref: o,
      $width: f,
      ...d,
      children: /* @__PURE__ */ e.jsx(te, { children: c })
    }
  );
}), ie = l(J)`
  &,
  > * {
    cursor: ${(t) => t.$isReadOnly ? "pointer" : "text"};
  }

  box-shadow: ${(t) => t.$isActive ? z("focusRings.brand.xs")(t) : "none"};
`, re = l(K)`
  display: ${(t) => t.$isDropDownIconVisible ? "inline" : "none"};
  &:hover {
    color: ${M("quarterary.hover")};
  }
`, se = l.div`
  display: inline-flex;
  gap: ${m("md")};
  cursor: ${(t) => t.$isReadOnly ? "auto" : "pointer"};
`, le = B(
  function(a, o) {
    const {
      onKeyDown: i,
      endAdornment: c,
      onInputChange: d,
      containerProps: f,
      readOnly: s = !0,
      onCloseMenuContext: h,
      onForceMenuContextOpen: y,
      isTypingActive: x = !1,
      selectedOptionLabel: b = "",
      isMenuContextOpen: g = !1,
      isDropDownIconVisible: $ = !0,
      autoClearOnSelect: C,
      ...D
    } = a, [S, v] = L(""), j = s ? b : x ? S : b, R = (u) => {
      u.key.toLowerCase() !== "tab" && (i == null || i(u), g || y == null || y());
    }, F = (u) => {
      u.currentTarget.value.length === 0 && h();
    }, O = () => {
      C && v("");
    };
    return /* @__PURE__ */ e.jsx(
      ie,
      {
        ref: o,
        readOnly: s,
        $isReadOnly: s,
        value: j,
        $isActive: g,
        onKeyUp: s ? void 0 : F,
        onBlur: O,
        onFocus: O,
        onChange: s ? void 0 : (u) => {
          d == null || d(u), v(u.target.value);
        },
        onKeyDown: s ? void 0 : R,
        endAdornment: /* @__PURE__ */ e.jsxs(se, { $isReadOnly: s, children: [
          c,
          /* @__PURE__ */ e.jsx(
            re,
            {
              width: 20,
              height: 20,
              variant: "chevron-down",
              $isDropDownIconVisible: $
            }
          )
        ] }),
        containerProps: f,
        ...D
      }
    );
  }
), ae = (t, a) => {
  const { label: o, value: i, onSelect: c, isSelected: d, ...f } = t, s = () => {
    c(i);
  };
  return /* @__PURE__ */ e.jsx(
    ee,
    {
      onClick: s,
      isSelected: d,
      ...f,
      children: o
    },
    a
  );
}, ce = l.input`
  display: none;
`, de = l(I.Button)`
  display: none;
`, ue = l.div`
  padding: ${m("md")};
`, pe = l.div`
  padding: ${m("md")};
`, De = (t) => {
  const {
    value: a,
    options: o,
    onClick: i,
    inputRef: c,
    onChange: d,
    emptyContent: f,
    onInputChange: s,
    isTypingActive: h,
    isLoading: y = !1,
    isDropDownIconVisible: x = !0,
    renderSelectItem: b = ae,
    autoClearOnSelect: g,
    ...$
  } = t, {
    refs: C,
    floatingStyles: D
  } = Q({
    floatingOptions: {
      placement: "bottom"
    },
    offset: 5
  }), S = k(null), v = k(null), w = k(null), j = g ? void 0 : o.find((r) => r.value === a), R = (r) => {
    var n;
    t.disabled || (i && i(r), (n = S.current) == null || n.click());
  }, F = (r) => {
    var p;
    const n = (p = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : p.set;
    if (w.current && n) {
      n.call(w.current, r);
      const P = new Event("input", {
        bubbles: !0
      });
      w.current.dispatchEvent(P);
    }
  }, O = (r) => {
    if (!d)
      return;
    const n = r.target.value, p = o.find(
      (H) => String(H.value) === n
    );
    if (!p)
      throw new Error(
        `Failed to map selected value from option: ${p}`
      );
    a !== p.value && d(r, p.value);
  }, u = () => o.map((r, n) => {
    const p = t.value === r.value;
    return b(
      {
        ...r,
        onSelect: F,
        isSelected: p
      },
      n
    );
  }), V = o.length === 0, N = f || /* @__PURE__ */ e.jsx(ue, { children: /* @__PURE__ */ e.jsx(T, { color: "secondary", children: "No data" }) });
  return /* @__PURE__ */ e.jsx(I, { children: (r) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsx(de, { ref: S }),
    /* @__PURE__ */ e.jsx(
      ce,
      {
        ref: (n) => {
          typeof c == "function" ? c(n) : c && (c.current = n), w.current = n;
        },
        onChange: O,
        value: a ?? ""
      }
    ),
    /* @__PURE__ */ e.jsx(
      le,
      {
        ref: v,
        onInputChange: s,
        isTypingActive: h,
        isMenuContextOpen: r.open,
        autoClearOnSelect: g,
        onCloseMenuContext: r.close,
        selectedOptionLabel: j == null ? void 0 : j.label,
        isDropDownIconVisible: x,
        onForceMenuContextOpen: () => {
          var n;
          return (n = S.current) == null ? void 0 : n.click();
        },
        containerProps: {
          onClick: R,
          ref: C.setReference
        },
        ...$
      }
    ),
    G(
      /* @__PURE__ */ e.jsx(
        oe,
        {
          ref: C.setFloating,
          style: D,
          textFieldRef: v,
          onFocus: () => {
            var n;
            return (n = v.current) == null ? void 0 : n.focus();
          },
          children: y ? /* @__PURE__ */ e.jsx(pe, { children: /* @__PURE__ */ e.jsx(T, { color: "secondary", children: "Loading..." }) }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: V ? N : u() })
        }
      ),
      document.body
    )
  ] }) });
};
export {
  De as B,
  ee as S,
  Y as a
};
