import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';
import { TenantPermission } from 'src/types/tenants';

export type Group = {
  id: string;
  displayName: string;
  permissions : TenantPermission[]
  users: {
    items: {
      user: {
        id: string;
        displayName: string;
      };
    }[];
  };
}

export type FetchTenantGroupsParams = {
  filter: TenantGroupsFilter;
} | {
  continuationToken: string;
};

export type TenantGroupsFilter = {
  query: string;
};

const fetchTenantGroupsQueryDocument = graphql(`
  query TenantGroups($tenantID: ID!, $filter: TenantGroupsFilter,$continuationToken: String) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        groups (filter: $filter, continuationToken: $continuationToken) {
          continuationToken
          totalCount
          pageSize
          pageNumber
          items {
            id
            displayName
            permissions
            users {
              items {
                user {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const fetchTenantGroups = async (tenantID: string, params?: FetchTenantGroupsParams) => {
  const variables = {
    tenantID,
    ...params
  };
  const tenantGroup = await graphQLClient.request(fetchTenantGroupsQueryDocument, variables);
  if ('groups' in tenantGroup.tenant) {
    return tenantGroup.tenant.groups;
  }

  return {
    items: [],
    continuationToken: null,
    totalCount: 0,
  };
};
