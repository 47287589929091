import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownItems } from '@oncore/ui/Dropdown';

const ChromeDropdownItems = () => {
  const navigate = useNavigate();

  return (
    <DropdownItems>
      <DropdownItem
        rightIcon={{
          variant: 'log-out'
        }}
        text="Sign out"
        onClick={() => navigate('/logout')} />
    </DropdownItems>
  );
};

export default ChromeDropdownItems;
