import { TenantTimesheetsTable } from 'src/features/tenant-timesheets';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from '../Tenant';

const TenantTimesheetsPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantTimesheetsTable tenantID={params.tenantID}/>
  );
};

export default TenantTimesheetsPage;
