import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import {
  getBreakpointMediaQuery,
  getSpacings,
} from '@oncore/ui/Theme';
import Typography from '@oncore/ui/Typography';
import { BaseTextField } from '@oncore/ui/TextField';
import { getTenantDetailsQuery } from './queries';
import { CountryIcon } from '@oncore/ui/Icon';
import Divider from '@oncore/ui/Divider';

export type TenantDetailsProps = {
  tenantID: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0 ${getSpacings('xl')} 0 ${getSpacings('xl')};
  }
`;

const FieldDivider = styled(Divider)`

  ${getBreakpointMediaQuery('max', 'lg')} {
    border-top: none;
    margin-top: 0;
  }

`;

const SectionContent = styled.div`
  display: flex;
  
  gap: ${getSpacings('4xl')};

  ${getBreakpointMediaQuery('max', 'lg')} {
    flex-direction: column;

    gap: ${getSpacings('sm')};
  }
`;

const FieldLabel = styled(Typography)`
  width: 280px;
`;

const StyledBaseTextField = styled(BaseTextField)`
  width: 512px;
  
  ${getBreakpointMediaQuery('max', 'lg')} {
    width: 100%;
  }
`;

const TenantDetails = (props: TenantDetailsProps) => {
  const { tenantID } = props;

  const query = useQuery(getTenantDetailsQuery(tenantID));

  if (!query.data) {
    return null;
  }

  const tenantData = query.data;

  return (
    <Container>
      <Typography size="lg" weight="semibold">
        Tenant details
      </Typography>
      <Typography size="sm" weight="regular" color="tertiary">
        Update the general tenant details here.
      </Typography>
      <Divider spacing="2xl"/>
      <SectionContent>
        <FieldLabel size="sm" weight="semibold">
          Company
        </FieldLabel>
        <StyledBaseTextField
          disabled={true}
          value={tenantData.company.displayName}/>
      </SectionContent>
      <FieldDivider/>
      {
        tenantData.__typename === 'Agency' && (
          <>
            <SectionContent>
              <FieldLabel size="sm" weight="semibold">
                Vendor
              </FieldLabel>
              <StyledBaseTextField
                disabled={true}
                value={tenantData.vendor.displayName || ''}/>
            </SectionContent>
            <FieldDivider/>
          </>
        )
      }
      <SectionContent>
        <FieldLabel size="sm" weight="semibold">
          Display name
        </FieldLabel>
        <StyledBaseTextField
          disabled={true}
          value={tenantData.displayName}/>
      </SectionContent>
      <FieldDivider/>
      <SectionContent>
        <FieldLabel size="sm" weight="semibold">
          Region
        </FieldLabel>
        <StyledBaseTextField
          disabled={true}
          value={tenantData.region.displayName}
          startAdornment={(
            <CountryIcon code={tenantData.region.code}/>
          )}/>
      </SectionContent>
    </Container>
  );
};

export default TenantDetails;
