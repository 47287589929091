import { RouteObject } from 'react-router-dom';

import TenantPage from './TenantPage';
import tenantDetailsRoute from '../TenantDetails/route';
import TenantIndexPage from './TenantIndexPage';
import { tenantPath } from './link';
import tenantUsersRoute from '../TenantUsers/route';

import tenantTimesheetsRoute from '../TenantTimesheets/route';
import tenantFeaturesRoute from '../TenantFeatures/route';
import tenantContractsRoute from '../TenantContracts/route';
import tenantSettingsRoute from '../TenantSettings/route';
import tenantGroupsRoute from '../TenantGroups/route';

const tenantRoute: RouteObject = {
  path: tenantPath,
  element: <TenantPage/>,
  children: [
    {
      path: '',
      element: <TenantIndexPage/>
    },
    tenantDetailsRoute,
    tenantFeaturesRoute,
    tenantSettingsRoute,
    tenantTimesheetsRoute,
    tenantContractsRoute,
    tenantGroupsRoute,
    tenantUsersRoute,
  ],
};

export default tenantRoute;
