import { PropsWithChildren, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { setTokenRoutine, setUserRoutine } from 'src/state/user/reducer';
import { getCurrentUserSelector } from 'src/state/user/selectors';

type AuthGuardProps = PropsWithChildren;

export const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;
  const { token } = useSelector(getCurrentUserSelector());
  const dispatch = useDispatch();
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect().then();
    }
  }, [ isAuthenticated, isLoading ]);

  useEffect(() => {
    (async () => {
      if (!token && !isLoading) {
        dispatch(
          setTokenRoutine.trigger(await getAccessTokenSilently())
        );
      }
    })();
  }, [ token, isLoading ]);

  useEffect(() => {
    if (user) {
      dispatch(setUserRoutine.trigger({
        name: user.name || '',
        email: user.email || '',
        avatarUrl: user?.picture || '',
        isEmailVerified: user.email_verified,
      }));
    }
  }, [user]);

  if (!user && isAuthenticated) {
    throw new Error('[AuthGuard] User is authenticated, however user information is not available');
  }

  if (!isAuthenticated || !token) {
    return null;
  }

  return children;
};

export default AuthGuard;
