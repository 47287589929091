import { RouteObject } from 'react-router-dom';

import UserProfilePage from './UserProfile.tsx';
import userProfileLink from './link';

const userProfileRoute: RouteObject = {
  path: userProfileLink(),
  element: <UserProfilePage/>,
};

export default userProfileRoute;
