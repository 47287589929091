import styled from 'styled-components';
import {
  useInfiniteQuery,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  TableHeaderCell,
  TableCell,
  EmptyTable,
  EmptyTableProps,
} from '@oncore/ui/Table';
import Typography from '@oncore/ui/Typography';
import OncoreTable, {
  renderDefaultRow,
  OncoreTableProps,
} from '@oncore/ui/shared/OncoreTable';
import { getTenantsQuery } from './queries';
import {
  FetchTenantsParams,
  Tenant,
  TenantsFilter,
} from './services';
import TenantTypeCell from './TenantTypeCell';

export type TenantsTableProps = {
  filter: TenantsFilter;
  onClearFilterClick: EmptyTableProps['onClearFilterClick'];
  onTenantClick?: (tenant: Tenant) => void;
};

const TenantUserCell = styled(TableCell)`
  justify-content: end;
`;

const TenantUserHeaderCell = styled(TableHeaderCell)`
  justify-content: end;
`;

const columnHelper = createColumnHelper<Tenant>();

const columns = [
  columnHelper.accessor('displayName', {
    header: () => (
      <TableHeaderCell
        span="320px">
        Tenant
      </TableHeaderCell>
    ),
    cell: (info) => (
      <TableCell
        span="320px">
        <Typography
          size="sm"
          weight="medium">
          {info.cell.getValue()}
        </Typography>
      </TableCell>
    ),
  }),
  columnHelper.accessor('type', {
    header: () => (
      <TableHeaderCell
        span="123px">
        Type
      </TableHeaderCell>
    ),
    cell: (info) => (
      <TenantTypeCell
        type={info.cell.getValue()}
        span="123px"/>
    ),
  }),
  columnHelper.accessor('region.displayName', {
    id: 'regionDisplayName',
    header: () => (
      <TableHeaderCell span="240px">
        Region
      </TableHeaderCell>
    ),
    cell: (info) => (
      <TableCell span="240px">
        <Typography size="sm">
          {info.cell.getValue()}
        </Typography>
      </TableCell>
    ),
  }),
  columnHelper.accessor('users.totalCount', {
    id: 'usersTotalCount',
    header: () => (
      <TenantUserHeaderCell size="120px">
        Users
      </TenantUserHeaderCell>
    ),
    cell: (info) => (
      <TenantUserCell size="120px">
        <Typography size="sm">
          {info.cell.getValue()}
        </Typography>
      </TenantUserCell>
    )
  }),
];

const TenantsTable = (props: TenantsTableProps) => {
  const {
    filter,
    onClearFilterClick,
    onTenantClick,
  } = props;

  const initialPageParam: FetchTenantsParams = {
    filter: filter,
    continuationToken: '',
  };

  const tenantsQuery = getTenantsQuery(filter);

  const infiniteQuery = useInfiniteQuery({
    ...tenantsQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await tenantsQuery.queryFn(data.pageParam);
    },
    getNextPageParam: (lastPage): FetchTenantsParams | undefined => {
      const { continuationToken } = lastPage;
      const params = continuationToken ? {
        continuationToken,
      } : undefined;

      return params;
    },
  });

  const tableData = useMemo(() => {
    if (infiniteQuery.data) {
      return infiniteQuery.data.pages.flatMap((page) => page.items);
    }

    return [];
  }, [infiniteQuery.data?.pages]);

  const handleRenderRow: OncoreTableProps<Tenant>['renderRow'] = (renderRowProps) => {
    const handleTenantClick = () => {
      onTenantClick && onTenantClick(renderRowProps.row.original);
    };

    return renderDefaultRow({
      ...renderRowProps,
      isClickable: true,
      onClick: handleTenantClick,
    });
  };

  if (!infiniteQuery.data) {
    return;
  }

  if (infiniteQuery.isFetched && tableData.length === 0) {

    return (
      <EmptyTable
        hasFilter={Boolean(filter.query)}
        onClearFilterClick={onClearFilterClick}/>
    );
  }

  return (
    <OncoreTable
      data={tableData}
      columns={columns}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 60,
      }}
      renderRow={handleRenderRow}
    />
  );
};

export default TenantsTable;
