import styled from 'styled-components';
import type { TenantsFilter as TenantsFilterValues } from './services';
import SearchTextField, {
  SearchTextFieldProps,
} from '@oncore/ui/shared/SearchTextField';

export type TenantsFilterProps = Pick<SearchTextFieldProps, 'onClearIconClick'> & {
  filter: TenantsFilterValues;
  onFilterChange?: (filter: TenantsFilterValues) => void;
  className?: string;
};

const Container = styled.div`
  display: flex;
`;

const TenantsFilter = (props: TenantsFilterProps) => {
  const {
    filter,
    onFilterChange,
    onClearIconClick,
    className,
  } = props;

  const handleChange: SearchTextFieldProps['onChange'] = (event) => {
    onFilterChange && onFilterChange({
      ...filter,
      query: event.target.value,
    });
  };

  return (
    <Container className={className}>
      <SearchTextField
        placeholder="Search tenants"
        value={filter.query}
        onChange={handleChange}
        onClearIconClick={onClearIconClick} />
    </Container>
  );
};

export default TenantsFilter;
