
import logout from './Logout/link';

import tenants from './Tenants/link';
import tenant from './Tenant/link';
import tenantDetails from './TenantDetails/link';
import tenantUsers from './TenantUsers/link';
import tenantGroups from './TenantGroups/link';
import tenantTimesheets from './TenantTimesheets/link';
import tenantFeatures from './TenantFeatures/link';
import tenantContracts from './TenantContracts/link';
import tenantSettings from './TenantSettings/link';

import users from './Users/link';
import user from './User/link';
import userProfile from './UserProfile/link';
import userDetails from './UserDetails/link';

const links = {
  logout,
  tenants,
  tenant,
  tenantDetails,
  tenantFeatures,
  tenantGroups,
  tenantTimesheets,
  tenantContracts,
  tenantSettings,
  tenantUsers,
  users,
  user,
  userProfile,
  userDetails,
} as const;

export default links;
