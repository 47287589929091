import SideBar, { SideBarItem } from '@oncore/ui/SideBar';

const ChromeSideBar = () => (
  <SideBar logo="omp">
    <SideBarItem
      icon={{ variant: 'building' }}
      text="Tenants"
      to="/tenants"
    />
    <SideBarItem
      icon={{ variant: 'users' }}
      text="Users"
      to="/users"
    />
  </SideBar>
);

export default ChromeSideBar;
