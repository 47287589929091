import { FeatureDetails } from 'src/components/FeaturesForm';
import { EnterpriseFeature, AgencyFeature } from 'src/types/tenants';

export type AgencyFeatureDetails = FeatureDetails<AgencyFeature>;

export type EnterpriseFeatureDetails = FeatureDetails<EnterpriseFeature>;

export const TENANT_AGENCY_FEATURES: AgencyFeatureDetails[] = [
  {
    id: AgencyFeature.Contracts,
    name: 'Contracts',
    description: 'Allows access to the Contracts functionality.'
  },
  {
    id: AgencyFeature.Reports,
    name: 'Reports',
    description: 'Allows access to the Reports functionality.'
  },
];

export const TENANT_ENTERPRISE_FEATURES: EnterpriseFeatureDetails[] = [
  {
    id: EnterpriseFeature.Positions,
    name: 'Positions',
    description: 'Allows access to the Positions functionality.'
  },
  {
    id: EnterpriseFeature.Placements,
    name: 'Placements',
    description: 'Allows access to the Placements functionality.'
  },
  {
    id: EnterpriseFeature.Contracts,
    name: 'Contracts',
    description: 'Allows access to the Contracts functionality.'
  },
  {
    id: EnterpriseFeature.Timesheets,
    name: 'Timesheets',
    description: 'Allows access to the Timesheets functionality.'
  },
  {
    id: EnterpriseFeature.Expenses,
    name: 'Expenses',
    description: 'Allows access to the Expenses functionality. (DO NOT USE, WORK IN PROGRESS)'
  },
  {
    id: EnterpriseFeature.CostCentres,
    name: 'Cost Centres',
    description: 'Allows access to the Cost Centres functionality.'
  },
  {
    id: EnterpriseFeature.Projects,
    name: 'Projects',
    description: 'Allows access to the Projects functionality.'
  },
  {
    id: EnterpriseFeature.SupplierPaymentsManagement,
    name: 'Supplier Payments Management (SPM)',
    description: 'Allows access to the Supplier Payments Management (SPM) functionality.'
  },
  {
    id: EnterpriseFeature.OrganisationalUnits,
    name: 'Organisational Units',
    description: 'Allows access to the Organisational Units functionality.'
  },
  {
    id: EnterpriseFeature.TimesheetQuickReview,
    name: 'Timesheets Quick Review',
    description: 'Allows use of Timesheets Quick Review,' +
      ' a dedicated view accessible to the timesheet approvers via Timesheets menu item.'
  },
  {
    id: EnterpriseFeature.Reports,
    name: 'Reports',
    description: 'Allows access to the Reports functionality.'
  },
  {
    id: EnterpriseFeature.SimplexityHomepage,
    name: 'Simplexity Homepage',
    description: 'Allows access to Simplexity Homepage. (DO NOT USE, WORK IN PROGRESS).'
  },
];
