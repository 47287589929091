import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';
import {
  AgencyFeature,
  EnterpriseFeature
} from 'src/types/tenants';

export type TenantFeature = AgencyFeature | EnterpriseFeature;

export type UpdateTenantAgencyFeaturesParams = {
  tenantID: string;
  features: AgencyFeature[];
};

export type UpdateTenantEnterpriseFeaturesParams = {
  tenantID: string;
  features: EnterpriseFeature[];
};

export type UpdateTenantFeaturesResult = {
  id: string;
};

const fetchTenantFeaturesQueryDocument = graphql(`
  query TenantFeatures($tenantID: ID!) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        __typename
        id
        displayName
        enterpriseFeatures: features
      }
    ... on Agency {
        __typename
        id
        displayName
        agencyFeatures: features
      }
    }
  }
`);

const updateAgencyFeaturesMutationDocument = graphql(`
  mutation UpdateAgencyFeatures($tenantID: ID!, $features: [AgencyFeature!]!) {
    tenant: updateAgency(
      tenantID: $tenantID,
      features: $features
      ) {
      id
    }
  }
`);

const updateEnterpriseFeaturesMutationDocument = graphql(`
  mutation UpdateEnterpriseFeatures($tenantID: ID!, $features: [EnterpriseFeature!]!) {
    tenant: updateEnterprise(
      tenantID: $tenantID,
      features: $features
      ) {
      id
    }
  }
`);

export const fetchTenantFeatures = async (tenantID: string) => {
  const { tenant } = await graphQLClient.request(fetchTenantFeaturesQueryDocument, {
    tenantID
  });

  return tenant;
};

export const updateAgencyFeatures = async (params: UpdateTenantAgencyFeaturesParams):
  Promise<UpdateTenantFeaturesResult> => {
  const { tenant } = await graphQLClient.request(updateAgencyFeaturesMutationDocument, params);

  return tenant;
};

export const updateEnterpriseFeatures = async (params: UpdateTenantEnterpriseFeaturesParams):
  Promise<UpdateTenantFeaturesResult> => {
  const { tenant } = await graphQLClient.request(updateEnterpriseFeaturesMutationDocument, params);

  return tenant;
};
