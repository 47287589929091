import { useQuery } from '@tanstack/react-query';
import { getUserDetailsQuery } from './queries';
import UserDetailsUpdatePage from './UserDetailsUpdatePage';

export type UserDetailsProps = {
  userID: string;
};

const UserDetails = (props: UserDetailsProps) => {
  const { userID } = props;

  const query = useQuery(getUserDetailsQuery(userID));

  if (!query.data) {
    return null;
  }

  return (
    <UserDetailsUpdatePage
      userID={userID}
      displayName={query.data.displayName}
    />
  );
};

export default UserDetails;
