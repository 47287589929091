import { TenantDetails } from 'src/features/tenant-details';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from '../Tenant';

const TenantDetailsPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantDetails tenantID={params.tenantID}/>
  );
};

export default TenantDetailsPage;
