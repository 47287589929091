import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';
import { WorkerType } from 'src/api/gql/graphql';
import { ContractsWorkerType } from 'src/types/tenants';

export type Contract = {
  id: string;
  displayName: string;
  startDate: string;
  endDate: string;
  role: string;
  workerType: string;
};

export type ContractsOverviewFilter = {
  query: string;
  startDate: string;
  endDate: string;
  endingContracts: boolean;
  workerType: WorkerType;
};

type DirectPtyLtdType = Contract & {
  type: ContractsWorkerType.directPtyLtd;
};

type PaygType = Contract & {
  type: ContractsWorkerType.payg;
};

export type ContractsWorkerTypes = DirectPtyLtdType | PaygType;

export type FetchTenantContractsParams = {
  filter: ContractsOverviewFilter;
} | {
  continuationToken: string;
};

const fetchTenantContractsQueryDocument = graphql(`
  query TenantContracts($tenantID: ID!, $filter: ContractsOverviewFilter,$continuationToken: String) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        contracts(filter: $filter, continuationToken: $continuationToken) {
          continuationToken
          totalCount
          pageSize
          pageNumber
          items {
            id
            displayName
            role
            workerType  
            startDate
            endDate
          }
        }
      }
    }
  }
`);

export const fetchTenantContracts = async (tenantID: string, params?: FetchTenantContractsParams) => {
  const variables = {
    tenantID,
    ...params
  };
  const tenantContract = await graphQLClient.request(fetchTenantContractsQueryDocument, variables);
  if ('contracts' in tenantContract.tenant) {
    return tenantContract.tenant.contracts;
  }

  return {
    items: [],
    continuationToken: null,
    totalCount: 0,
  };
};
