import { RouteObject } from 'react-router-dom';

import { tenantGroupsPath } from './link';
import TenantGroupsPage from './TenantGroupsPage';

const tenantGroupsRoute: RouteObject = {
  path: tenantGroupsPath,
  element: <TenantGroupsPage/>,
};

export default tenantGroupsRoute;
