import { useMemo } from 'react';
import Badge, { BadgeProps } from '@oncore/ui/Badge';
import { TableCell, TableCellProps } from '@oncore/ui/Table';
import { ContractsWorkerTypes } from './services';

export type TenantContractWorkerTypeCellProps =
  Pick<ContractsWorkerTypes, 'workerType'> & Omit<TableCellProps, 'children'>;

const TenantContractWorkerTypeCell = (props: TenantContractWorkerTypeCellProps) => {
  const {
    workerType,
    ...cellProps
  } = props;
  const color = useMemo<BadgeProps['color']>(() => {
    switch (workerType) {
      case 'Direct Pty Ltd':
        return 'green';
      case 'PAYG':
        return 'blue';
      default:
        return 'brand';
    }
  }, [workerType]);

  return (
    <TableCell {...cellProps}>
      <Badge
        color={color}
        text={workerType}/>
    </TableCell>
  );
};

export default TenantContractWorkerTypeCell;
