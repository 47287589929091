import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';
import { TimesheetStatus } from 'src/types/tenants';

export type Timesheet = {
  id: string;
  worker: {
    displayName?: string | null;
  };
  contract: {
    displayName?: string | null;
  };
  startDate: string;
  endDate: string;
  status: string;
};

export type TimesheetsFilter = {
  query: string;
};

type ApprovedStatus = Timesheet & {
  type: TimesheetStatus.approved;
};

type RejectedStatus = Timesheet & {
  type: TimesheetStatus.rejected;
};

type SubmittedStatus = Timesheet & {
  type: TimesheetStatus.submitted;
};

export type TimesheetStatusType = ApprovedStatus | RejectedStatus | SubmittedStatus;

export type FetchTenantTimesheetsParams = {
  filter: TimesheetsFilter;
} | {
  continuationToken: string;
};

const fetchTenantTimesheetsQueryDocument = graphql(`
  query TenantTimesheets($tenantID: ID!, $filter: TimesheetsFilter,$continuationToken: String) {
    tenant(tenantID: $tenantID) {
    ... on Enterprise {
        timesheets(filter: $filter, continuationToken: $continuationToken) {
          continuationToken
          totalCount
          pageSize
          pageNumber
          items {
            id
            worker {
              displayName
            }
            contract {
              displayName
            }
            startDate
            endDate
            status
          }
        }
      }
    }
  }
`);

export const fetchTenantTimesheets = async (tenantID: string, params?: FetchTenantTimesheetsParams) => {
  const variables = {
    tenantID,
    ...params
  };
  const tenantTimesheet = await graphQLClient.request(fetchTenantTimesheetsQueryDocument, variables);
  if ('timesheets' in tenantTimesheet.tenant) {
    return tenantTimesheet.tenant.timesheets;
  }

  return {
    items: [],
    continuationToken: null,
    totalCount: 0,
  };
};
