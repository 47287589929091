import { fetchTenantDetails } from './services';

export const tenantKeys = {
  tenant: (tenantID: string) => [
    'tenant',
    tenantID,
  ] as const,
  tenantDetails: (tenantID: string) => [
    ...tenantKeys.tenant(tenantID),
    'details'
  ] as const,
  tenantFeatures: (tenantID: string) => [
    ...tenantKeys.tenant(tenantID),
    'features'
  ] as const,
  tenantSettings: (tenantID: string) => [
    ...tenantKeys.tenant(tenantID),
    'settings'
  ] as const
};

export const getTenantDetailsQuery = (tenantID: string) => ({
  queryKey: tenantKeys.tenantDetails(tenantID),
  queryFn: () => fetchTenantDetails(tenantID),
});
