import * as yup from 'yup';

export const updateEnterpriseSettingsSchema = yup.object({
  tenantID: yup.string().required(),
  settings: yup.object({
    isCostCenterFieldRequired: yup.boolean().required(),
    isLineManagerRequired: yup.boolean().required(),
    isProjectFieldRequired: yup.boolean().required(),
    isTimesheetApproversRequired: yup.boolean().required(),
    workCoverIndustryClassificationCodeID: yup.string().required(),
    workCoverIndustryClassificationCode: yup.string().required(),
  }).required(),
});
