import { css as o } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBorder as i, getSpacings as t } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const z = (m, r) => {
  switch (m) {
    case "horizontal":
      return o`
        width: 100%;
        
        border-top: 1px solid ${i("secondary")};
        margin: ${t(r)} 0 ${t(r)} 0;
      `;
    case "vertical":
      return o`
        height: 100%;

        border-left: 1px solid ${i("secondary")};
        margin: 0 ${t(r)} 0 ${t(r)};
      `;
    default:
      return o``;
  }
};
export {
  z as getDividerStyles
};
