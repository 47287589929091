import { Typography } from '@oncore/ui';
import PageWrapper from 'src/components/page-templates/PageWrapper.tsx';
import { useQuery } from '@tanstack/react-query';
import { appVersionQuery } from 'src/features/current-user/queries.ts';

const UserProfilePage = () => {
  const query = useQuery(appVersionQuery());

  return (
    <PageWrapper isLoading={query.isLoading}>
      <Typography variant="display" size="sm">User profile</Typography>
    </PageWrapper>
  );
};

export default UserProfilePage;
