import { css as r, keyframes as e } from "styled-components";
import { r as i } from "../../polished.esm-wTGEn2h0.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBackground as m, getPaletteColors as o } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const O = {
  sm: r`
    width: 36px;
    height: 20px;
  `,
  md: r`
    width: 44px;
    height: 24px;
  `
}, B = {
  sm: r`
    width: 16px;
    height: 16px;
  `,
  md: r`
    width: 20px;
    height: 20px;
  `
}, C = (t) => e`
    from {
      background: ${m("tertiary")(t)};
      box-shadow: 0px 0px 0px 4px ${i(o("gray.400")(t), 0.2)};
    }
    
    to {
      background: ${o(t.$color)(t)[600]};
      box-shadow: 0px 0px 0px 4px ${i(o(t.$color)(t)[400], 0.2)};
    }
  `, z = (t) => e`
    from {
      background: ${o(t.$color)(t)[600]};
      box-shadow: 0px 0px 0px 4px ${i(o(t.$color)(t)[400], 0.2)};
    }
    
    to {
      background: ${m("tertiary")(t)};
      box-shadow: 0px 0px 0px 4px ${i(o("gray.400")(t), 0.2)};
    }
  `, P = e`
  0% {
    left: 2px;
  }
  
  50% {
    left: 25%;
  }
  
  100% {
    left: calc(50%);
  }
`, S = e`
  0% {
    left: calc(50%);
  }

  50% {
    left: 25%;
  }
  
  100% {
    left: 2px;
  }
`;
export {
  B as buttonSizes,
  O as sizeStyles,
  S as switchOffButtonKeyFrame,
  z as switchOffContainerKeyFrame,
  P as switchOnButtonKeyFrame,
  C as switchOnContainerKeyFrame
};
