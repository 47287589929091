import { useMemo } from 'react';
import Badge, { BadgeProps } from '@oncore/ui/Badge';
import { TableCell, TableCellProps } from '@oncore/ui/Table';
import { TimesheetStatusType } from './services';

export type TenantTimesheetStatusCellProps = Pick<TimesheetStatusType, 'status'> & Omit<TableCellProps, 'children'>;

const TenantTimesheetStatusCell = (props: TenantTimesheetStatusCellProps) => {
  const {
    status,
    ...cellProps
  } = props;
  const color = useMemo<BadgeProps['color']>(() => {
    switch (status) {
      case 'Approved':
        return 'green';
      case 'Rejected':
        return 'brand';
      default:
        return 'blue';
    }
  }, [status]);

  return (
    <TableCell {...cellProps}>
      <Badge
        color={color}
        text={status}/>
    </TableCell>
  );
};

export default TenantTimesheetStatusCell;
