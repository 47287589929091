import { fetchUsers, UsersFilter } from './services';

export const usersKeys = {
  users: ['users'] as const,
  usersList: (filter?: UsersFilter) => [
    ...usersKeys.users,
    'list',
    filter,
  ] as const,
};

export const getUsersQuery = (filter?: UsersFilter) => ({
  queryKey: usersKeys.usersList(filter),
  queryFn: fetchUsers,
});
