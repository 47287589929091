import userLink, { UserNavigationContext } from '../User/link';

export type UserDetailsNavigationContext = UserNavigationContext;

const userDetailsLink = (params: UserNavigationContext['params']) => {
  return `${userLink(params)}/details` as const;
};

export const userDetailsPath = userDetailsLink({
  userID: ':userID',
});

export default userDetailsLink;
