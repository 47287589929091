import { Outlet } from 'react-router-dom';
import Layout from '@oncore/ui/Layout';
import ChromeTopBar from './ChromeTopBar.tsx';
import ChromeSideBar from './ChromeSideBar.tsx';

const Chrome = () => (
  <Layout
    product="omp"
    topbar={<ChromeTopBar />}
    sidebar={<ChromeSideBar />}
    content={<Outlet />}
  />
);

export default Chrome;
