import {
  matchPath,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import PageContent, {
  PageContentHeader,
} from '@oncore/ui/PageContent';
import Badge from '@oncore/ui/Badge';
import TabControl, {
  TabList,
} from '@oncore/ui/TabControl';
import {
  getBreakpointMediaQuery,
  getSpacings,
  useMediaQuery,
} from '@oncore/ui/Theme';
import { getTenantDetailsQuery } from 'src/features/tenant-details';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from './link';
import links from '../links';
import { Tenant } from 'src/features/tenants/services';
import Screen, {
  ScreenHeader,
  ScreenBody,
} from '@oncore/ui/Screen';

type TenantTabsContext = {
  tenantID: string;
  currentPath: string;
  type: Tenant['type'];
};

const createTabsContext = (context: TenantTabsContext) => {
  const {
    tenantID,
    currentPath,
    type,
  } = context;

  const defaultParams = {
    tenantID,
  };

  const defaultTabs = [
    {
      label: 'Details',
      link: links.tenantDetails(defaultParams),
    },
    {
      label: 'Features',
      link: links.tenantFeatures(defaultParams),
    },
    {
      label: 'Settings',
      link: links.tenantSettings(defaultParams),
      isHidden: type === 'Agency',
    },
    {
      label: 'Users',
      link: links.tenantUsers(defaultParams),
      hasTable: true,
    },
    {
      label: 'Groups',
      link: links.tenantGroups(defaultParams),
      hasTable: true,
    },
    {
      label: 'Positions',
      link: '/positions',
      disabled: true,
    },
    {
      label: 'Placements',
      link: '/placements',
      disabled: true,
    },
    {
      label: 'Contracts',
      link: links.tenantContracts(defaultParams),
      hasTable: true,
    },
    {
      label: 'Timesheets',
      link: links.tenantTimesheets(defaultParams),
      hasTable: true,
    },
  ];

  const navigationTabs = defaultTabs.filter((tab) => !tab.isHidden);

  const tabs = navigationTabs.map(({
    label,
    disabled,
  }) => ({
    label,
    disabled,
  }));

  const defaultTabIndex = navigationTabs.findIndex((tab) => {
    return matchPath({
      path: tab.link,
      end: false,
    }, currentPath);
  });

  return {
    navigationTabs,
    tabs,
    defaultTabIndex: defaultTabIndex === -1 ? 0 : defaultTabIndex,
  };
};

const StyledTabControl = styled(TabControl)`
  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0px ${getSpacings('xl')} 0px ${getSpacings('xl')};
  }
`;

const StyledPageContent = styled(PageContent)`
  width: 100%;
`;

const TenantPage = () => {
  const {
    params,
    location,
  } = useNavigationContext<TenantNavigationContext>();

  const navigate = useNavigate();

  const query = useQuery(getTenantDetailsQuery(params.tenantID));

  const tabsContext = useMemo(() => {

    if (!query.data) {
      return {
        defaultTabIndex: 0,
        navigationTabs: [],
        tabs: [],
      };
    }

    return createTabsContext({
      tenantID: params.tenantID,
      type: query.data.__typename,
      currentPath: location.pathname,
    });
  }, [
    params.tenantID,
    query.data,
    location.pathname,
  ]);

  const isMobileView = useMediaQuery('mobile');

  const handleTabIndexChange = (index: number) => {
    const selectedTab = tabsContext.navigationTabs[index];

    navigate(selectedTab.link);
  };

  const handleScreenClose = () => {
    navigate(links.tenants());
  };

  if (!query.data) {
    return null;
  }

  const tenantData = query.data;

  const Content = (
    <StyledPageContent
      removeBottomPaddingInMobile={tabsContext.navigationTabs[tabsContext.defaultTabIndex]?.hasTable}>
      <PageContentHeader title={tenantData.displayName}>
        <Badge
          text={tenantData.__typename}
          color={tenantData.__typename === 'Enterprise' ? 'blue' : 'brand'}/>
        <Badge
          text={tenantData.id}
          color="gray"/>
      </PageContentHeader>
      <StyledTabControl
        variant="shadow"
        color="gray"
        defaultIndex={tabsContext.defaultTabIndex}
        onChange={handleTabIndexChange}>
        <TabList tabs={tabsContext.tabs}/>
      </StyledTabControl>
      <Outlet/>
    </StyledPageContent>
  );

  if (isMobileView) {
    return (
      <Screen
        isOpen={isMobileView}
        onClose={handleScreenClose}>
        <ScreenHeader/>
        <ScreenBody>
          {Content}
        </ScreenBody>
      </Screen>
    );
  }

  return Content;
};

export default TenantPage;
