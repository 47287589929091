import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

export type UpdateUserDisplayNameResult = {
  id: string
  displayName: string;
  email: string;
}

export type UpdateUserDisplayNameParams = {
  userID: string;
  displayName: string;
}

const fetchUserDetailsQueryDocument = graphql(`
  query User($userID: ID!) {
    user(userID: $userID) {
      id
      displayName
      email
    }
  }
`);

const updateUserDisplayNameMutationDocument = graphql(`
    mutation UpdateUserDisplayName($userID: ID!, $displayName: String!) {
        user: updateUserDisplayName(userID: $userID, displayName: $displayName) {
            id
            displayName
            email
        }
    }
`);

export const updateUserDisplayNameMutation = async (params: UpdateUserDisplayNameParams):
  Promise<UpdateUserDisplayNameResult> => {
  const { user } = await graphQLClient.request(updateUserDisplayNameMutationDocument, params);

  return user;
};

export const fetchUserDetails = async (userID: string) => {
  const { user } = await graphQLClient.request(fetchUserDetailsQueryDocument, {
    userID
  });

  return user;
};
