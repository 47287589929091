import {
  fetchTenantContracts,
  ContractsOverviewFilter
} from './services';
import { tenantKeys } from '../tenant-details';

export const tenantContractKeys = {
  contracts: (tenantID: string, filter?: ContractsOverviewFilter) => [
    ...tenantKeys.tenant(tenantID),
    'contracts',
    filter,
  ] as const,
};

export const getTenantContractsQuery = (tenantID: string, filter?: ContractsOverviewFilter) => ({
  queryKey: tenantContractKeys.contracts(tenantID, filter),
  queryFn: fetchTenantContracts,
});

