import { I as h } from "../../Icon-qMvz1KAz.js";
import { j as t } from "../../jsx-runtime-NmMXPjeo.js";
import { useMemo as m, Fragment as p, Suspense as c } from "react";
import u from "./countryVariants.es.js";
import "./variants.es.js";
const j = (n) => {
  const {
    code: o,
    ...e
  } = n, s = m(() => {
    const r = u[o];
    return r || p;
  }, [o]);
  return /* @__PURE__ */ t.jsx(c, { children: /* @__PURE__ */ t.jsx(s, { ...e }) });
};
export {
  j as CountryIcon,
  h as default
};
