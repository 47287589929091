import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

type NavigationContextValue = {
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
};

export const useNavigationContext = <T extends NavigationContextValue>() => {
  const params = useParams();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return {
    params: params as T['params'],
    location,
    queryParams: queryParams as T['queryParams'],
  };
};
