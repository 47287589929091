import tenantsLink from '../Tenants/link';

export type TenantNavigationContext = {
  params: {
    tenantID: string;
  }
};

const tenantLink = (params: TenantNavigationContext['params']) => {
  return `${tenantsLink()}/${params.tenantID}` as const;
};

export const tenantPath = tenantLink({
  tenantID: ':tenantID'
});

export default tenantLink;
