import styled from 'styled-components';
import { FieldPath, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getBreakpointMediaQuery,
  getSpacings,
  getWidths,
} from '@oncore/ui/Theme';
import Typography from '@oncore/ui/Typography';
import {
  InitialSettings,
  WICCode,
  UpdateEnterpriseSettingsParams,
} from './services';
import Divider from '@oncore/ui/Divider';
import { getUpdateEnterpriseSettingsMutation } from './mutations';
import { updateEnterpriseSettingsSchema } from './schemas';
import SettingsPageFooter from './SettingsPageFooter';
import Select from '@oncore/ui/Select';
import Checkbox from '@oncore/ui/Checkbox';
import { EnterpriseFeature } from '../../types/tenants';

export type SettingsPageProps = {
  tenantID: string;
  settings: InitialSettings;
  features: EnterpriseFeature[];
  wicCodes: WICCode[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${getBreakpointMediaQuery('max', 'lg')} {
    padding: 0 ${getSpacings('xl')} 0 ${getSpacings('xl')};
  }
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${getSpacings('xl')} ${getSpacings('4xl')};
  align-self: stretch;
  flex-wrap: wrap;
`;

const SectionLabel = styled.div`
  display: flex;
  min-width: 200px;
  max-width: 280px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const SectionInputs = styled.div`
  display: flex;
  min-width: ${getWidths('xxs')};
  max-width: 512px;

  ${getBreakpointMediaQuery('max', 'lg')} {
    max-width: 100%;
  }
    
  flex-direction: column;
  align-items: flex-start;
  gap: ${getSpacings('xl')};
  flex: 1 0 0;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const falseByDefault = (
  setting: boolean | null | undefined) => {
  return (setting ?? false);
};

const SettingsPage = (props: SettingsPageProps) => {
  const {
    tenantID,
    settings,
    wicCodes,
    features,
  } = props;
  const includesCostCenters = features.includes(EnterpriseFeature.CostCentres);
  const includesProjects = features.includes(EnterpriseFeature.Projects);
  const includesPlacements = features.includes(EnterpriseFeature.Placements);

  const queryClient = useQueryClient();

  const settingsMutation = useMutation(getUpdateEnterpriseSettingsMutation(queryClient));

  const defaultSettings = {
    isCostCenterFieldRequired: falseByDefault(settings?.isCostCenterFieldRequired),
    isProjectFieldRequired: falseByDefault(settings?.isProjectFieldRequired),
    isTimesheetApproversRequired: falseByDefault(settings?.isTimesheetApproversRequired),
    isLineManagerRequired: falseByDefault(settings?.isLineManagerRequired),
    workCoverIndustryClassificationCodeID: settings?.workCoverIndustryClassificationCodeID ?? wicCodes[0].id,
    workCoverIndustryClassificationCode: '',
  };

  const { 
    getValues, 
    formState: { isDirty }, 
    setValue, 
    watch,
  } = useForm({
    resolver: yupResolver(updateEnterpriseSettingsSchema),
    defaultValues: {
      tenantID: tenantID,
      settings: defaultSettings,
    }
  });

  const handleSubmit = () => {
    if (isDirty) {
      settingsMutation.mutate(getValues());
    }
  };

  const handleSettingsChange = (name: FieldPath<UpdateEnterpriseSettingsParams>, value: string | boolean) => {
    setValue(name, value, {
      shouldDirty: true
    });
  };

  const handleWICCodesSettingsChange = (value: string) => {
    const newWicCode = wicCodes.find((x) => x.id === value);
    const label = newWicCode?.code + ' / ' + newWicCode?.displayName;

    setValue('settings.workCoverIndustryClassificationCodeID', value, {
      shouldDirty: true
    });
    setValue('settings.workCoverIndustryClassificationCode', label, {
      shouldDirty: true
    });
  };

  const renderCostCentreSettings = () => {
    if (includesCostCenters) {
      return (
        <Checkbox
          label="Cost centre field should be mandatory"
          helperMessage="Available in placement form and contract details."
          checked={watch('settings.isCostCenterFieldRequired')}
          onChange={(event) =>
            handleSettingsChange('settings.isCostCenterFieldRequired', event.target.checked)}
        />
      );
    }
    return null;
  };

  const renderProjectSettings = () => {
    if (includesProjects) {
      return (
        <Checkbox
          label="Project field should be mandatory"
          helperMessage="Available in placement form and contract details."
          checked={watch('settings.isProjectFieldRequired')}
          onChange={(event) =>
            handleSettingsChange('settings.isProjectFieldRequired', event.target.checked)}
        />
      );
    }
    return null;
  };

  const renderPlacementsSettings = () => {
    if (includesPlacements) {
      return (
        <>
          <Divider spacing="2xl"/>
          <SectionContainer>
            <SectionLabel>
              <Typography size="sm" weight="semibold" color="secondary">
                Placements settings
              </Typography>
            </SectionLabel>
            <SectionInputs>
              <Checkbox
                label="Timesheet approvers field should be mandatory"
                checked={watch('settings.isTimesheetApproversRequired')}
                onChange={(event) =>
                  handleSettingsChange('settings.isTimesheetApproversRequired', event.target.checked)}
              />
              <Checkbox
                label="Line manager field should be mandatory"
                checked={watch('settings.isLineManagerRequired')}
                onChange={(event) =>
                  handleSettingsChange('settings.isLineManagerRequired', event.target.checked)}
              />
            </SectionInputs>
          </SectionContainer>
        </>
      );
    }
    return null;
  };

  return (
    <Container>
      <Typography size="lg" weight="semibold">
        Settings
      </Typography>
      <Typography size="sm" weight="regular" color="tertiary">
        Configure the tenant settings and visible inputs.
      </Typography>
      <Divider spacing="2xl"/>
      <SectionContainer>
        <SectionLabel>
          <Typography size="sm" weight="semibold" color="secondary">
            General settings
          </Typography>
        </SectionLabel>
        <SectionInputs>
          <StyledSelect
            label="Workcover Industry Classification (WIC) Code"
            options={wicCodes.map((x) => ({
              value: x.id,
              label: x.code + ' / ' + x.displayName,
            }))}
            value={watch('settings.workCoverIndustryClassificationCodeID')}
            onChange={(_, newValue) =>
              handleWICCodesSettingsChange(newValue.toString())}
          />
          {
            renderCostCentreSettings()
          }
          {
            renderProjectSettings()
          }
        </SectionInputs>
      </SectionContainer>
      {
        renderPlacementsSettings()
      }
      <SettingsPageFooter
        onClick={handleSubmit}
        disabled={settingsMutation.isPending}
      />
    </Container>
  );
};

export default SettingsPage;
