import {
  put, takeLatest, call
} from 'typed-redux-saga';

import { setEnvironmentRoutine } from './reducer';
import { getEnvironmentVariables, graphQLClient } from 'src/environment';

function* setEnvironment() {
  const environment = yield* call(getEnvironmentVariables);

  yield* put(setEnvironmentRoutine.request(environment));

  graphQLClient.setEndpoint(`${environment.api}/graphql/manage`);

  yield* put(setEnvironmentRoutine.success());
}

export default function* environmentSagas() {
  yield* takeLatest(setEnvironmentRoutine.TRIGGER, setEnvironment);
}
