import { Typography } from '@oncore/ui';
import styled from 'styled-components';
import { PropsWithChildren } from 'react';

const Container = styled.div`
  padding: 4px 16px;
`;

type PageWrapperProps = PropsWithChildren<{
  isLoading?: boolean;
}>;

const PageWrapper = (props: PageWrapperProps) => {

  if (props.isLoading) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {props.children}
    </Container>
  );
};

export default PageWrapper;
