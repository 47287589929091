import { tenantKeys } from '../tenant-details';
import { updateEnterpriseSettingsMutation, UpdateEnterpriseSettingsResult } from './services';
import { QueryClient } from '@tanstack/react-query';

export const getUpdateEnterpriseSettingsMutation = (queryClient: QueryClient) => ({
  mutationFn: updateEnterpriseSettingsMutation,
  onSuccess: (data: UpdateEnterpriseSettingsResult) => {
    queryClient.invalidateQueries({ queryKey: tenantKeys.tenantSettings(data.id)});
  }
});
