import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import u from "styled-components";
import { Tab as $ } from "@headlessui/react";
import { Fragment as j } from "react";
import { tabSizeStyles as S, tabVariantStyles as v, tabOrientationStyles as P, tabListVariantStyles as L, tabListOrientationStyles as z } from "./styles.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteColors as C, getPaletteBase as B, getSpacings as b, getPaletteText as F } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import "../../styles-YtnfNTLW.js";
import w from "../Typography/index.es.js";
import { u as x } from "../../hooks-cD42jdlD.js";
import { T as Lt } from "../../hooks-cD42jdlD.js";
import { B as W } from "../../BaseSelect-Xm84Ve8E.js";
import "../FieldControl/index.es.js";
import "react-phone-number-input";
import { useMediaQuery as k } from "../Theme/hooks.es.js";
import "../../polished.esm-wTGEn2h0.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "../CssBaseline/normalize.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "react-dom";
import "../../Icon-qMvz1KAz.js";
import "../Icon/variants.es.js";
import "../Icon/countryVariants.es.js";
import "../TextField/index.es.js";
import "../../useAppFloating-h0t839w5.js";
import "@floating-ui/react-dom";
const I = (t) => /* @__PURE__ */ o.jsx(
  w,
  {
    color: "none",
    weight: "semibold",
    ...t
  }
), O = u.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;

  color: ${C("gray.500")};
  background-color: ${B("transparent")};
  padding: ${b("md")} ${b("lg")};
  gap: ${b("md")};
  
  ${(t) => S[t.$size]};
  ${(t) => v[t.$variant]};
  ${(t) => P[t.$orientation]};
  
  &:hover {
    cursor: pointer;
  }
  
  &:disabled {
    cursor: not-allowed;
    
    color : ${F("disabled")};
  }
  
`, H = (t) => {
  const {
    children: e,
    size: i = "md",
    variant: r = "lined",
    color: n = "brand",
    orientation: a = "horizontal",
    ...l
  } = t;
  return /* @__PURE__ */ o.jsx($, { as: j, children: (m) => /* @__PURE__ */ o.jsx(
    O,
    {
      $size: i,
      $variant: r,
      $color: n,
      $orientation: a,
      $isSelected: m.selected,
      ...l,
      children: typeof e == "string" ? /* @__PURE__ */ o.jsx(I, { children: e }) : e
    }
  ) });
}, M = (t) => {
  const {
    index: e,
    onClick: i,
    ...r
  } = t, {
    variant: n,
    orientation: a,
    color: l,
    size: m,
    setSelectedIndex: p
  } = x(), c = (h) => {
    p(e), i && i(h);
  };
  return /* @__PURE__ */ o.jsx(
    H,
    {
      variant: n,
      color: l,
      size: m,
      orientation: a,
      onClick: c,
      ...r
    }
  );
}, V = u($.List)`
  display: flex;
  align-items: center;
  overflow: auto;
  scrollbar-width: thin;
  
  gap: ${b("xs")};
  
  width: ${(t) => t.$isFullWidth && t.$orientation === "horizontal" ? "100%" : "initial"};
  
  > * {
    flex: ${(t) => t.$isFullWidth ? 1 : "none"};
  }
  
  ${(t) => L[t.$variant]};
  ${(t) => z[t.$orientation]};
`, E = (t) => {
  const {
    children: e,
    variant: i = "lined",
    isFullWidth: r,
    orientation: n = "horizontal",
    ...a
  } = t;
  return /* @__PURE__ */ o.jsx(
    V,
    {
      $variant: i,
      $isFullWidth: r,
      $orientation: n,
      ...a,
      children: e
    }
  );
}, Q = u(E)`
  display: ${(t) => t.$isHidden ? "none" : "flex"};
`, Tt = (t) => {
  const {
    tabs: e,
    ...i
  } = t, {
    variant: r,
    orientation: n,
    isFullWidth: a,
    selectedIndex: l,
    setSelectedIndex: m,
    onChange: p
  } = x(), c = k("mobile"), h = e.map((d, s) => ({
    value: s,
    label: d.label,
    disabled: d.disabled
  })), g = (d, s) => {
    const {
      label: y,
      ...T
    } = d;
    return /* @__PURE__ */ o.jsx(
      M,
      {
        index: s,
        ...T,
        children: y
      },
      s
    );
  }, f = (d, s) => {
    m(s), p && p(s);
  };
  return /* @__PURE__ */ o.jsxs(o.Fragment, { children: [
    /* @__PURE__ */ o.jsx(
      Q,
      {
        $isHidden: c,
        variant: r,
        isFullWidth: a,
        orientation: n,
        ...i,
        children: e.map(g)
      }
    ),
    c && /* @__PURE__ */ o.jsx(
      W,
      {
        value: l,
        options: h,
        onChange: f
      }
    )
  ] });
}, jt = (t) => /* @__PURE__ */ o.jsx($.Panel, { ...t }), St = (t) => /* @__PURE__ */ o.jsx($.Panels, { ...t });
export {
  M as Tab,
  I as TabLabel,
  Tt as TabList,
  jt as TabPanel,
  St as TabPanelList,
  Lt as default,
  x as useTabControl
};
