import tenantLink, { TenantNavigationContext } from '../Tenant/link';

export type TenantGroupsNavigationContext = TenantNavigationContext;

const tenantGroupsLink = (params: TenantNavigationContext['params']) => {
  return `${tenantLink(params)}/groups` as const;
};

export const tenantGroupsPath = tenantGroupsLink({
  tenantID: ':tenantID',
});

export default tenantGroupsLink;
