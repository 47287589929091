export enum EnterpriseFeature {
  Contracts = 'contracts',
  CostCentres = 'costCentres',
  Expenses = 'expenses',
  OrganisationalUnits = 'organisationalUnits',
  Placements = 'placements',
  Positions = 'positions',
  Projects = 'projects',
  Reports = 'reports',
  SimplexityHomepage = 'simplexityHomepage',
  SupplierPaymentsManagement = 'supplierPaymentsManagement',
  TimesheetQuickReview = 'timesheetQuickReview',
  Timesheets = 'timesheets'
}

export enum TenantPermission {
  ContractUser = 'contractUser',
  ExpenseReview = 'expenseReview',
  ExpenseUser = 'expenseUser',
  PlacementCreate = 'placementCreate',
  PlacementUser = 'placementUser',
  PositionCreate = 'positionCreate',
  PositionUser = 'positionUser',
  PositionReview = 'positionReview',
  PositionSend = 'positionSend',
  ReportCreate = 'reportCreate',
  ReportUser = 'reportUser',
  TenantAdmin = 'tenantAdmin',
  TimesheetFinance = 'timesheetFinance',
  TimesheetQuickReview = 'timesheetQuickReview',
  TimesheetReview = 'timesheetReview',
  TimesheetUser = 'timesheetUser'
}

export enum AgencyFeature {
  Contracts = 'contracts',
  Reports = 'reports'
}

export enum TimesheetStatus {
  approved = 'Approved',
  rejected = 'Rejected',
  submitted = 'Submitted'
}

export enum ContractsWorkerType {
  directPtyLtd = 'Direct Pty Ltd',
  payg = 'PAYG',
  partyPtyLtd = '3rd Party Pty Ltd',
  soleTrader = 'Sole Trader',
  spmPtyLtd = 'SPM as Pty Ltd',
  spmPayg = 'SPM as PAYG'
}

