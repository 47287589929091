import {
  fetchTenantGroups,
  TenantGroupsFilter
} from './services';
import { tenantKeys } from '../tenant-details';

export const tenantGroupKeys = {
  groups: (tenantID: string, filter?: TenantGroupsFilter) => [
    ...tenantKeys.tenant(tenantID),
    'groups',
    filter,
  ] as const,
};

export const getTenantGroupsQuery = (tenantID: string, filter?: TenantGroupsFilter) => ({
  queryKey: tenantGroupKeys.groups(tenantID, filter),
  queryFn: fetchTenantGroups,
});
