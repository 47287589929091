import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from './components/Auth';
import { store } from './state/store';
import Loader from './components/Loader';
import ThemeProvider from '@oncore/ui/Theme';
import queryClient from './queryClient.ts';
import AppRouter from 'src/components/AppRouter/AppRouter.tsx';

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Loader>
            <AuthProvider>
              <AppRouter/>
            </AuthProvider>
          </Loader>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
