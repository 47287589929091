import {
  fetchTenants,
  TenantsFilter
} from './services';

export const tenantsKeys = {
  tenants: ['tenants'] as const,
  listTenants: (filter?: TenantsFilter) => [
    ...tenantsKeys.tenants,
    'list',
    filter,
  ] as const,
};

export const getTenantsQuery = (filter?: TenantsFilter) => ({
  queryKey: tenantsKeys.listTenants(filter),
  queryFn: fetchTenants,
});
