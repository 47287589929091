import { j as r } from "../../jsx-runtime-NmMXPjeo.js";
import "../../styles-YtnfNTLW.js";
import m from "../Typography/index.es.js";
import a from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as c } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
const d = (t) => {
  const {
    text: o,
    className: i
  } = t;
  return /* @__PURE__ */ r.jsx(
    m,
    {
      size: "sm",
      weight: "medium",
      color: "secondary",
      className: i,
      children: o
    }
  );
}, x = a.div`
  display: flex;
  width: 320px;
  flex-direction: column;;
  align-items: flex-start;
  gap: ${c("sm")};
`, $ = (t) => {
  const {
    label: o,
    helperMessage: i,
    errorMessage: e,
    className: p,
    children: l
  } = t, s = e || i, n = !!e;
  return /* @__PURE__ */ r.jsxs(
    x,
    {
      className: p,
      children: [
        typeof o == "string" ? /* @__PURE__ */ r.jsx(d, { text: o }) : o,
        l,
        !!s && /* @__PURE__ */ r.jsx(
          m,
          {
            size: "sm",
            color: n ? "error" : void 0,
            children: s
          }
        )
      ]
    }
  );
};
export {
  d as FieldControlLabel,
  $ as default
};
