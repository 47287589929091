import { useRef as o, useEffect as S, useMemo as W, useReducer as k, useCallback as C } from "react";
function P(u, r, c) {
  var h = this, i = o(null), l = o(0), n = o(null), a = o([]), f = o(), m = o(), b = o(u), v = o(!0);
  b.current = u;
  var x = typeof window < "u", d = !r && r !== 0 && x;
  if (typeof u != "function")
    throw new TypeError("Expected a function");
  r = +r || 0;
  var D = !!(c = c || {}).leading, E = !("trailing" in c) || !!c.trailing, s = "maxWait" in c, M = "debounceOnServer" in c && !!c.debounceOnServer, y = s ? Math.max(+c.maxWait || 0, r) : null;
  S(function() {
    return v.current = !0, function() {
      v.current = !1;
    };
  }, []);
  var O = W(function() {
    var A = function(t) {
      var e = a.current, g = f.current;
      return a.current = f.current = null, l.current = t, m.current = b.current.apply(g, e);
    }, p = function(t, e) {
      d && cancelAnimationFrame(n.current), n.current = d ? requestAnimationFrame(t) : setTimeout(t, e);
    }, T = function(t) {
      if (!v.current)
        return !1;
      var e = t - i.current;
      return !i.current || e >= r || e < 0 || s && t - l.current >= y;
    }, q = function(t) {
      return n.current = null, E && a.current ? A(t) : (a.current = f.current = null, m.current);
    }, F = function t() {
      var e = Date.now();
      if (T(e))
        return q(e);
      if (v.current) {
        var g = r - (e - i.current), R = s ? Math.min(g, y - (e - l.current)) : g;
        p(t, R);
      }
    }, w = function() {
      if (x || M) {
        var t = Date.now(), e = T(t);
        if (a.current = [].slice.call(arguments), f.current = h, i.current = t, e) {
          if (!n.current && v.current)
            return l.current = i.current, p(F, r), D ? A(i.current) : m.current;
          if (s)
            return p(F, r), A(i.current);
        }
        return n.current || p(F, r), m.current;
      }
    };
    return w.cancel = function() {
      n.current && (d ? cancelAnimationFrame(n.current) : clearTimeout(n.current)), l.current = 0, a.current = i.current = f.current = n.current = null;
    }, w.isPending = function() {
      return !!n.current;
    }, w.flush = function() {
      return n.current ? q(Date.now()) : m.current;
    }, w;
  }, [D, s, r, y, E, d, x, M]);
  return O;
}
function j(u, r) {
  return u === r;
}
function z(u, r) {
  return r;
}
function G(u, r, c) {
  var h = c && c.equalityFn || j, i = k(z, u), l = i[0], n = i[1], a = P(C(function(m) {
    return n(m);
  }, [n]), r, c), f = o(u);
  return h(f.current, u) || (a(u), f.current = u), [l, a];
}
export {
  P as c,
  G as o
};
