import { createColumnHelper } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  TableHeaderCell,
  EmptyTable,
  EmptyTableProps
} from '@oncore/ui/Table';
import OncoreTable, { OncoreTableProps, renderDefaultRow } from '@oncore/ui/shared/OncoreTable';
import {
  FetchUsersParams,
  User,
  UsersFilter,
} from './services';
import { getUsersQuery } from './queries';
import UserTableCell from './UserTableCell';

export type UsersTableProps = {
  filter: UsersFilter;
  onClearFilterClick: EmptyTableProps['onClearFilterClick'];
  onUserClick?: (user: User) => void;
};

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor('email', {
    header: () => (
      <TableHeaderCell>
        User
      </TableHeaderCell>
    ),
    cell: (info) => {
      const {
        displayName
      } = info.row.original;

      return (
        <UserTableCell
          displayName={displayName}
          emailAddress={info.getValue()}/>
      );
    },
  }),

];

const UsersTable = (props: UsersTableProps) => {
  const {
    filter,
    onClearFilterClick,
    onUserClick
  } = props;

  const usersQuery = getUsersQuery(filter);

  const initialPageParam: FetchUsersParams = {
    filter: filter,
    continuationToken: ''
  };

  const infiniteQuery = useInfiniteQuery({
    ...usersQuery,
    initialPageParam,
    queryFn: async (data) => {
      return await usersQuery.queryFn(data.pageParam);
    },
    getNextPageParam: (lastPage) => {
      const { continuationToken } = lastPage;

      return continuationToken ? {
        continuationToken,
      } : undefined;
    },
  });

  const queryData = infiniteQuery.data;

  const tableData = useMemo(() => {
    if (queryData) {
      return queryData.pages.flatMap((page) => page.items);
    }

    return [];
  }, [queryData?.pages]);

  const handleRenderRow: OncoreTableProps<User>['renderRow'] = (renderRowProps) => {
    const handleUserClick = () => {
      onUserClick && onUserClick(renderRowProps.row.original);
    };

    return renderDefaultRow({
      ...renderRowProps,
      isClickable: true,
      onClick: handleUserClick,
    });
  };

  if (infiniteQuery.isFetched && tableData.length === 0) {

    return (
      <EmptyTable
        hasFilter={Boolean(filter.query)}
        onClearFilterClick={onClearFilterClick}/>
    );
  }

  return (
    <OncoreTable
      columns={columns}
      data={tableData}
      infiniteDataLoaderOptions={{
        isFetchingNextPage: infiniteQuery.isFetchingNextPage,
        hasNextPage: infiniteQuery.hasNextPage,
        fetchNextPage: infiniteQuery.fetchNextPage,
        estimateSize: () => 40,
      }}
      renderRow={handleRenderRow}
    />
  );
};

export default UsersTable;
