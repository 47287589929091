import { Navigate } from 'react-router-dom';

import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from './link';

const TenantIndexPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <Navigate
      to={`/tenants/${params.tenantID}/details`}
      replace={true}/>
  );
};

export default TenantIndexPage;
