import { TenantGroupsTable } from 'src/features/tenant-groups';
import { useNavigationContext } from 'src/components/AppRouter/hooks';
import { TenantNavigationContext } from '../Tenant';

const TenantGroupsPage = () => {
  const { params } = useNavigationContext<TenantNavigationContext>();

  return (
    <TenantGroupsTable tenantID={params.tenantID}/>
  );
};

export default TenantGroupsPage;
