import { graphql } from 'src/api/gql';
import { graphQLClient } from 'src/environment';

export type FetchTenantUsersParams = {
  tenantID: string;
  continuationToken?: string;
};

export type TenantUserWithGroups = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile?: string | null;
  };
  groups: {
    items: Array<{
      displayName: string;
    }>;
    totalCount: number;
  }
};

const fetchTenantUsersQueryDocument = graphql(`
  query TenantUsers(
  $tenantID: ID!
  $filter: TenantUsersFilter
  $continuationToken: String
  $enterpriseGroupFilter: EnterpriseUserGroupsFilter
  $agencyGroupFilter: AgencyUserGroupsFilter
) {
  tenant(tenantID: $tenantID) {
  ... on Enterprise {
      __typename
      users(continuationToken: $continuationToken, filter: $filter) {
        continuationToken
        items {
          user {
            id
            firstName
            lastName
            email
            mobile
          }
          groups(filter: $enterpriseGroupFilter) {
            items {
              displayName
            }
            totalCount
          }
        }
      }
    }
  ... on Agency {
      __typename
      users(continuationToken: $continuationToken, filter: $filter) {
        continuationToken
        items {
          user {
            id
            firstName
            lastName
            email
            mobile
          }
          groups(filter: $agencyGroupFilter) {
            items {
              displayName
            }
            totalCount
          }
        }
      }
    }
  }
}
`);

export const fetchTenantUsers = async (params: FetchTenantUsersParams) => {
  const groupFilter = {
    take: 3,
  };

  const variables = {
    ...params,
    enterpriseGroupFilter: groupFilter,
    agencyGroupFilter: groupFilter,
  };

  const result = await graphQLClient.request(fetchTenantUsersQueryDocument, variables);

  const usersResult = result.tenant.users;

  return {
    ...usersResult,
    continuationToken: usersResult.continuationToken ?? '',
  };
};


