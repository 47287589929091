import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEnvironmentRoutine } from 'src/state/environment/reducer';
import { getEnvironmentSelector } from 'src/state/environment/selectors';

type LoaderProps = PropsWithChildren & {
  loading?: ReactNode;
}

function Loader(props: LoaderProps) {
  const {
    children,
    loading = 'Loading...'
  } = props;
  const { isReady } = useSelector(getEnvironmentSelector());
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReady) {
      dispatch(setEnvironmentRoutine.trigger());
    }
  }, [isReady]);

  if (!isReady) {
    return loading;
  }

  return children;
}

export default Loader;
