import styled from 'styled-components';

import { TableCell, TableCellProps } from '@oncore/ui/Table';
import { getSpacings } from '@oncore/ui/Theme';
import AvatarLabelGroup from '@oncore/ui/AvatarLabelGroup';

export type UserTableCellProps = {
  displayName: string;
  emailAddress: string;
} & Omit<TableCellProps, 'children'>;

const Container = styled(TableCell)`
  display: flex;
  
  > div {
    width: 100%;
  }
  
  gap: ${getSpacings('lg')};
`;

const UserTableCell = (props: UserTableCellProps) => {
  const {
    displayName,
    emailAddress,
    ...tableCellProps
  } = props;

  return (
    <Container {...tableCellProps}>
      <AvatarLabelGroup
        avatar={{
          variant: 'initials',
          displayName,
        }}
        text={displayName}
        supportingText={emailAddress}
        size="md"/>
    </Container>
  );
};

export default UserTableCell;
