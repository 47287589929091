import styled from 'styled-components';

import type { UsersFilter as UsersFilterValues } from './services';
import SearchTextField, { SearchTextFieldProps } from '@oncore/ui/shared/SearchTextField';

export type UsersFilterProps = Pick<SearchTextFieldProps, 'onClearIconClick'> & {
  filter: UsersFilterValues;
  onFilterChange?: (filter: UsersFilterValues) => void;
  className?: string;
};

const Container = styled.div`
  display: flex;
`;

const UsersFilter = (props: UsersFilterProps) => {
  const {
    filter,
    onFilterChange,
    onClearIconClick,
    className,
  } = props;

  const handleChange: SearchTextFieldProps['onChange'] = (event) => {
    onFilterChange && onFilterChange({
      ...filter,
      query: event.target.value,
    });
  };

  return (
    <Container className={className}>
      <SearchTextField
        value={filter.query}
        onChange={handleChange}
        onClearIconClick={onClearIconClick}
        placeholder="Search users" />
    </Container>
  );
};

export default UsersFilter;
