import { RouteObject } from 'react-router-dom';

import { tenantTimesheetsPath } from './link';
import TenantTimesheetsPage from './TenantTimesheetsPage';

const tenantTimesheetsRoute: RouteObject = {
  path: tenantTimesheetsPath,
  element: <TenantTimesheetsPage/>,
};

export default tenantTimesheetsRoute;
