import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import { B as a } from "../../BaseSelect-Xm84Ve8E.js";
import { S as Q, a as T } from "../../BaseSelect-Xm84Ve8E.js";
import "../../styles-YtnfNTLW.js";
import "../Typography/index.es.js";
import l from "../FieldControl/index.es.js";
import "react";
import "styled-components";
import "../Theme/themes.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../palettes-foWluF5S.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../Theme/utils.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/index.es.js";
import "../CssBaseline/normalize.es.js";
import "@headlessui/react";
import "react-dom";
import "../../Icon-qMvz1KAz.js";
import "../Icon/variants.es.js";
import "../Icon/countryVariants.es.js";
import "../TextField/index.es.js";
import "../../useAppFloating-h0t839w5.js";
import "@floating-ui/react-dom";
import "../../clsx.m-7d8NmkQq.js";
const J = (r) => {
  const {
    label: m,
    helperMessage: i,
    errorMessage: t,
    className: p,
    ...e
  } = r, s = !!t;
  return /* @__PURE__ */ o.jsx(
    l,
    {
      label: m,
      helperMessage: i,
      errorMessage: t,
      className: p,
      children: /* @__PURE__ */ o.jsx(
        a,
        {
          isInvalid: s,
          ...e
        }
      )
    }
  );
};
export {
  a as BaseSelect,
  Q as SelectItem,
  T as SelectItemLabel,
  J as default
};
