import { TenantPermission } from 'src/types/tenants';

export const TENANT_PERMISSION_LABEL_MAPPER : Record<TenantPermission, string> = {
  [TenantPermission.ContractUser]: 'Contract User',
  [TenantPermission.ExpenseReview]: 'Expense Review',
  [TenantPermission.ExpenseUser]: 'Expense User',
  [TenantPermission.PlacementCreate]: 'Placement Create',
  [TenantPermission.PlacementUser]: 'Placement User',
  [TenantPermission.PositionCreate]: 'Position Create',
  [TenantPermission.PositionUser]: 'Position User',
  [TenantPermission.PositionReview]: 'Position Review',
  [TenantPermission.PositionSend]: 'Position Send',
  [TenantPermission.ReportCreate]: 'Report Create',
  [TenantPermission.ReportUser]: 'Report User',
  [TenantPermission.TenantAdmin]: 'Tenant Admin',
  [TenantPermission.TimesheetFinance]: 'Timesheet Finance',
  [TenantPermission.TimesheetQuickReview]: 'Timesheet Quick Review',
  [TenantPermission.TimesheetReview]: 'Timesheet Review',
  [TenantPermission.TimesheetUser]: 'Timesheet User'
};
